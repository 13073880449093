import React from "react";

const PlusIcon = ({ ...props }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="5.08887" width="12" height="1.69618" fill="#24272E" />
      <rect
        x="5.14453"
        y="11.8733"
        width="11.8732"
        height="1.71429"
        transform="rotate(-90 5.14453 11.8733)"
        fill="#24272E"
      />
    </svg>
  );
};

export default PlusIcon;
