import React from "react";
import PropTypes from "prop-types";
import NumberCounter from "../../../../../components/NumberCounter/NumberCounter";

const propTypes = {
  t: PropTypes.func.isRequired,
  minRooms: PropTypes.number.isRequired,
  setMinRooms: PropTypes.func.isRequired,
};

const RoomFilter = ({ t, minRooms, setMinRooms }) => {
  return (
    <div className="RoomFilter">
      <NumberCounter
        value={minRooms}
        label={t("rooms")}
        disableDecrement={minRooms < 2}
        onDecrement={() => setMinRooms(minRooms - 1)}
        onIncrement={() => setMinRooms(minRooms + 1)}
        inputName="minRooms"
      />
    </div>
  );
};

RoomFilter.propTypes = propTypes;

export default RoomFilter;
