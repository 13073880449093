import React from "react";
import PropTypes from "prop-types";

const MoreInfoCircular = ({
  width = 18,
  height = 18,
  fill = "black",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="cls-1"
        d="M9,1.75C5,1.75,1.75,5,1.75,9s3.25,7.25,7.25,7.25,7.25-3.25,7.25-7.25S13,1.75,9,1.75ZM.25,9C.25,4.17,4.17,.25,9,.25s8.75,3.92,8.75,8.75-3.92,8.75-8.75,8.75S.25,13.83,.25,9Z"
      />
      <path d="M8.47,6.3c-.15-.15-.22-.33-.22-.54s.07-.39,.22-.54,.33-.22,.54-.22,.38,.07,.53,.22,.22,.33,.22,.54-.07,.39-.22,.54c-.15,.15-.32,.22-.53,.22s-.39-.07-.54-.22Zm1.12,.98v5.72h-1.18V7.28h1.18Z" />
    </svg>
  );
};

MoreInfoCircular.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default MoreInfoCircular;
