import React from "react";
import PropTypes from "prop-types";

const HomeIconInCircle = ({ width = 60, height = 60, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      {...props}
    >
      <circle
        cx="30"
        cy="30"
        r="29.5"
        fill="#9AB4DE"
        fillOpacity=".2"
        stroke="#9AB4DE"
      />
      <path
        fill="#fff"
        d="M37.69 40.245h-14.4c-.99 0-1.8-.85-1.8-1.9v-11.43l9-6.67 9 6.67v11.43c0 1.05-.81 1.9-1.8 1.9Z"
      />
      <path
        fill="#170C37"
        fillRule="evenodd"
        d="m30.49 19 10 7.411v11.934c0 1.552-1.21 2.9-2.8 2.9h-14.4c-1.59 0-2.8-1.348-2.8-2.9V26.41l10-7.411Zm-8 8.418v10.927c0 .547.41.9.8.9h14.4c.39 0 .8-.353.8-.9V27.418l-8-5.929-8 5.93Z"
        clipRule="evenodd"
      />
      <path
        fill="#EDF1F4"
        d="M31.99 31.745h-3c-.55 0-1 .45-1 1v7h5v-7c0-.55-.45-1-1-1Z"
      />
      <path
        fill="#170C37"
        fillRule="evenodd"
        d="M27.39 32.745c0-.882.719-1.6 1.6-1.6h3c.881 0 1.6.718 1.6 1.6v7.6h-6.2v-7.6Zm1.6-.4c-.218 0-.4.18-.4.4v6.4h3.8v-6.4c0-.22-.181-.4-.4-.4h-3Z"
        clipRule="evenodd"
      />
      <path
        fill="#170C37"
        d="M21.36 25.796 19 27.476l2.36 2.92v-4.6ZM39.64 25.796l2.35 1.68-2.35 2.92v-4.6Z"
      />
      <path
        fill="#170C37"
        d="m30.76 21.105-8.28 5.95-.04 2.72 8.79-6.16 7.77 5.55v-1.46l-8.24-6.6Z"
        opacity=".1"
      />
    </svg>
  );
};

HomeIconInCircle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default HomeIconInCircle;
