const HELFENDE_WAENDE_LOGOS = {
  en: "/public/images/refugee-listings/logos/helfende-waende-en.svg",
  de: "/public/images/refugee-listings/logos/helfende-waende-de.svg",
  ru: "/public/images/refugee-listings/logos/helfende-waende-ru.svg",
  uk: "/public/images/refugee-listings/logos/helfende-waende-uk.svg",
};

export default (locale) => {
  return HELFENDE_WAENDE_LOGOS[locale === "fr" ? "en" : locale];
};
