import React from "react";
import PropTypes from "prop-types";
import { misc } from "@wunderflats/constants";
import { canUseDOM } from "../../utils/dom";
import { isServicedApartment } from "../../utils/listing-utils";
import { create as createListingViewModel } from "../../utils/listing-view-model";
import ImageGallery from "../ImageGallery/ImageGallery";
import ListingDetailsBrief from "../ListingDetailsBrief/ListingDetailsBrief";
import ListingPrice from "../ListingPrice/ListingPrice";
import ServicedApartmentBadge from "../ServicedApartmentBadge/ServicedApartmentBadge";
import ShortlistButton from "../Shortlist/ShortlistButton";
import StudentExclusivePill from "../StudentExclusivePill/StudentExclusivePill";

const { labels } = misc;

const propTypes = {
  t: PropTypes.func.isRequired,
  city: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.object),
  url: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  listing: PropTypes.object,
  highlight: PropTypes.bool,
  query: PropTypes.object,
  onSelectImage: PropTypes.func,
  isSmallScreen: PropTypes.bool,
  dataLayerKey: PropTypes.string,
  extractContent: PropTypes.func.isRequired,
  featureFlags: PropTypes.arrayOf(PropTypes.object),
  coverImageLoadingStrategy: PropTypes.oneOf(["lazy", "eager"]),
};

class Listing extends React.Component {
  constructor({ t, lang }) {
    super();
    this.lvm = createListingViewModel(t, lang);
    this.containerRef = React.createRef();
  }

  render() {
    const {
      t,
      url,
      city,
      lang,
      highlight = false,
      listing = {},
      dataLayerKey,
      extractContent,
      coverImageLoadingStrategy = "lazy",
      onClick = () => {},
      onMouseEnter = () => {},
      onMouseLeave = () => {},
      isSmallScreen = false,
    } = this.props;
    const title = extractContent(listing.title);

    const placeholderImagePath = "/public/images/listing-bg.jpg";

    const placeholder = {
      pictures: [
        {
          urls: {
            thumbnail: placeholderImagePath,
            small: placeholderImagePath,
            medium: placeholderImagePath,
            large: placeholderImagePath,
            ogImage: placeholderImagePath,
            original: placeholderImagePath,
          },
        },
      ],
    };
    const listingImages = listing?.pictures?.length
      ? listing.pictures
      : placeholder.pictures;

    const { from, to, maxPrice, minAccommodates, minSize, minRooms } =
      this.props.query || {};
    const query = {};
    if (from) query.from = from;
    if (to) query.to = to;
    if (maxPrice) query.maxPrice = maxPrice;
    if (minAccommodates) query.minAccommodates = minAccommodates;
    if (minSize) query.minSize = minSize;
    if (minRooms) query.minRooms = minRooms;
    if (dataLayerKey) query.dataLayerKey = dataLayerKey;
    const queryStr = new URLSearchParams(query).toString();
    const toHighlight = highlight ? "Listing--highlight" : "";
    const pageUrl =
      this.lvm.permalinkUrl(listing) + (queryStr ? `?${queryStr}` : "");

    const canUseDom = canUseDOM();
    const listingWidth = this.containerRef.current?.offsetWidth;
    // listingWidth - 10%
    const popoverWidth = listingWidth && listingWidth - listingWidth / 10;

    const servicedApartmentCategory = this.props.categories?.find(
      ({ label }) => label === labels.CATEGORY_SERVICED_APARTMENTS,
    );

    return (
      <article
        className={`Listing ${toHighlight}`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-groupid={listing.groupId}
        data-testid="Listing"
        ref={this.containerRef}
      >
        {listing.restrictions && listing.restrictions.onlyStudentsAllowed && (
          <StudentExclusivePill t={t} />
        )}
        {isServicedApartment(listing) && (
          <ServicedApartmentBadge
            popoverWidth={popoverWidth}
            city={city}
            query={query}
            slug={servicedApartmentCategory?.slug}
          />
        )}
        <div className="Listing-ImageGallery">
          <a
            onClick={onClick}
            href={pageUrl}
            target={isSmallScreen ? "_self" : "_blank"}
            data-listing={listing._id}
            title={title}
            className="Listing-ImageGallery-link"
          />
          <ImageGallery
            images={listingImages}
            showThumbnails={false}
            keyboardNavigation={false}
            onSelectImage={this.props.onSelectImage}
            coverImage={listing.coverImage}
            coverImageLoadingStrategy={coverImageLoadingStrategy}
            sizes="
            (min-width: 680px) 370px,
            90vw"
          />
          {canUseDom && (
            <ShortlistButton
              className="Listing-ShortlistButton"
              groupId={listing.groupId}
              t={t}
              url={url}
              eventLabel="Search Results Page"
              clickableArea={[3, 3, 3, 3]}
            />
          )}
        </div>
        <div className="Listing-description">
          <a
            onClick={onClick}
            href={pageUrl}
            target={isSmallScreen ? "_self" : "_blank"}
            data-listing={listing._id}
            title={title}
            className="Listing-link"
          />
          <h3 className="Listing-descriptionTitle">{title}</h3>
          <ListingPrice light t={t} lang={lang} price={listing.price} />

          <div className="Listing-details">
            <ListingDetailsBrief t={t} listing={listing} />
          </div>
        </div>
      </article>
    );
  }
}

Listing.propTypes = propTypes;

export default Listing;
