/**
 * Debounce with requestAnimationFrame for better performance.
 * https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
 */
const debounce = (fn, ...args) => {
  // Setup a timer
  let timeout;

  // Return a function to run debounced
  return function functionWithDebounce() {
    // If there's a timer, cancel it
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }

    // Setup the new requestAnimationFrame()
    timeout = window.requestAnimationFrame(() => {
      fn.apply(this, args);
    });
  };
};

export default debounce;
