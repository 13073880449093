import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import MinusIcon from "../Icons/MinusIcon";
import PlusIcon from "../Icons/PlusIcon";

import "./NumberCounter.scss";

const propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  disableDecrement: PropTypes.bool,
  disableIncrement: PropTypes.bool,
  onDecrement: PropTypes.func,
  onIncrement: PropTypes.func,
  inputName: PropTypes.string,
  icon: PropTypes.node,
  className: PropTypes.string,
};

const NumberCounter = ({
  value,
  label,
  disableDecrement,
  disableIncrement,
  onIncrement,
  onDecrement,
  inputName,
  icon,
  className,
}) => (
  <div className={classnames(className, "NumberCounter")}>
    {inputName && <input name={inputName} value={value} type="hidden" />}
    {icon && <span className="NumberCounter-icon">{icon}</span>}
    <span className="NumberCounter-label">{label}</span>
    <span className="NumberCounter-btnContainer">
      <button
        type="button"
        className="NumberCounter-btn"
        disabled={disableDecrement}
        onClick={onDecrement}
        data-testid="NumberCounter-btn-minus"
      >
        <MinusIcon className="NumberCounter-minusIcon" />
      </button>
      <span className="NumberCounter-value">{value}</span>
      <button
        type="button"
        className="NumberCounter-btn"
        disabled={disableIncrement}
        onClick={onIncrement}
        data-testid="NumberCounter-btn-plus"
      >
        <PlusIcon className="NumberCounter-plusIcon" />
      </button>
    </span>
  </div>
);

NumberCounter.propTypes = propTypes;

export default NumberCounter;
