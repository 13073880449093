import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { LISTINGS_PAGE_TABLET_BREAKPOINT } from "../../spages/spa/Pages/ListingsPage/ListingsPage/utils";
import dateUtils from "../../utils/date";
import { isChild } from "../../utils/dom";
import Popover from "../_Legacy/Popover/Popover";
import DateTextInput from "../DateTextInput/DateTextInput";
import DateWidget from "../DateWidget/DateWidget";

const DateInput = forwardRef((props, ref) => {
  const {
    t,
    className,
    disabled,
    label,
    mobileLabel,
    name,
    placeholder,
    jumbo,
    borderless,
    inlineLabel,
    forcePreferredYPosition,
    onChange,
    onBlur,
    onClickOutside,
    onSelect,
    popOverClassName,
    renderDay,
    isDateAvailable = (date, available) => available,
    setAttributes,
    minDate,
    maxDate,
    autoFocus,
    value,
    defaultValue,
  } = props;

  const [state, setState] = useState({
    dateValue: value || defaultValue ? dayjs.utc(value || defaultValue) : null,
    showingDateWidget: false,
  });

  useImperativeHandle(ref, () => ({
    focus: () => {
      focus();
    },
  }));

  const dateWidgetContainerRef = useRef(null);
  const dateTextInputRef = useRef(null);
  const dateWidgetInnerRef = useRef(null);
  const dateWidgetMaskRef = useRef(null);
  const dateWidgetRef = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      focus();
    }
    return () => {
      blur();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFocus]);

  useEffect(() => {
    if (typeof value !== "undefined") {
      setState((prevState) => ({
        ...prevState,
        dateValue: value ? dayjs.utc(value) : null,
      }));
    }
  }, [value]);

  function onFocus() {
    if (
      window.innerWidth <= LISTINGS_PAGE_TABLET_BREAKPOINT ||
      document.documentElement.classList.contains("touch")
    ) {
      document.activeElement.blur();
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, showingDateWidget: true }));
        document.addEventListener("click", onClickAnywhere);
        document.addEventListener("keydown", onKeyDown);
      }, 0);
    } else {
      setState((prevState) => ({ ...prevState, showingDateWidget: true }));
      document.addEventListener("click", onClickAnywhere);
      document.addEventListener("keydown", onKeyDown);
    }
  }

  function focus() {
    dateTextInputRef?.current?.focus();
  }

  function blur() {
    setState((prevState) => ({ ...prevState, showingDateWidget: false }));
    if (dateTextInputRef?.current) dateTextInputRef.current.blur();
    document.removeEventListener("keydown", onKeyDown);
    if (onBlur) onBlur();
  }

  function animateOut() {
    const dateWidget = ReactDOM.findDOMNode(dateWidgetInnerRef?.current);
    const dateWidgetMask = ReactDOM.findDOMNode(dateWidgetMaskRef?.current);
    dateWidget.classList.add("is-hidden");
    dateWidgetMask.classList.add("is-hidden");
    setTimeout(() => {
      setState((prevState) => ({ ...prevState, showingDateWidget: false }));
      blur();
    }, 250);

    if (onClickOutside) {
      onClickOutside();
    }
  }

  function onClickAnywhere(e) {
    const target = e.target;
    const dateWidgetContainer = ReactDOM.findDOMNode(
      dateWidgetContainerRef?.current,
    );
    const dateTextInput = ReactDOM.findDOMNode(dateTextInputRef?.current);
    if (
      target === dateWidgetContainer ||
      isChild(dateWidgetContainer, target)
    ) {
      return;
    }
    if (target === dateTextInput || isChild(dateTextInput, target)) {
      return;
    }
    blur();
  }

  function onKeyDown(e) {
    // ENTER
    if (e.keyCode === 13) {
      if (onSelect) onSelect();
      blur();
    }

    // ESC
    if (e.keyCode === 27) {
      blur();
    }
  }

  const onSelectDate = (date) => {
    if (!onChangeDate(date)) return;
    if (onSelect) onSelect();
    blur();
  };

  function onChangeDate(date) {
    const available =
      date &&
      isDateAvailable(
        date,
        dateUtils.isAvailableBetween(minDate, date, maxDate),
      );

    if (date && !available) return false;

    // if component is uncontrolled
    if (typeof value === "undefined") {
      setState((prevState) => ({
        ...prevState,
        dateValue: date ? dayjs.utc(date) : null,
      }));
    }

    // if component is controlled
    if (onChange) {
      onChange(date);
    }

    return true;
  }

  const { dateValue, showingDateWidget } = state;

  const input = (
    <DateTextInput
      data-testid="year-input"
      t={t}
      readOnly={false}
      disabled={disabled}
      ref={dateTextInputRef}
      label={label}
      name={name}
      placeholder={placeholder}
      onChange={onChangeDate}
      onFocus={onFocus}
      onBlur={blur}
      value={dateValue ? dateValue.format("YYYY-MM-DD") : ""}
      jumbo={jumbo}
      borderless={borderless}
      inlineLabel={inlineLabel}
      className={className}
    />
  );

  return (
    <Popover
      show={showingDateWidget}
      around={input}
      className={`Popover--date-input ${popOverClassName}`}
      forcePreferredYPosition={forcePreferredYPosition}
    >
      <div
        onMouseDown={(e) => e.preventDefault()}
        ref={dateWidgetContainerRef}
        className={`Popover-card${className ? ` ${className}` : ""}`}
      >
        <div
          ref={dateWidgetMaskRef}
          className="DateWidget-mask"
          onClick={animateOut}
        />
        <div className="DateWidget-inner" ref={dateWidgetInnerRef}>
          <DateWidget
            name={name}
            t={t}
            label={label}
            mobileLabel={mobileLabel}
            ref={dateWidgetRef}
            renderDay={renderDay}
            isDateAvailable={isDateAvailable}
            setAttributes={setAttributes}
            minDate={minDate}
            maxDate={maxDate}
            onSelect={onSelectDate}
            selected={dateValue ? dateValue.format("YYYY-MM-DD") : ""}
          />
        </div>
      </div>
    </Popover>
  );
});

const propTypes = {
  t: PropTypes.func.isRequired,
  label: PropTypes.string,
  mobileLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  isDateAvailable: PropTypes.func,
  setAttributes: PropTypes.func,
  renderDay: PropTypes.func,
  onChange: PropTypes.func,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  jumbo: PropTypes.bool,
  borderless: PropTypes.bool,
  inlineLabel: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  onClickOutside: PropTypes.func,
  popOverClassName: PropTypes.string,
  forcePreferredYPosition: PropTypes.bool,
};

DateInput.propTypes = propTypes;

export default DateInput;
