/* eslint-disable react/prop-types */
import React from "react";

const Triangle = () => {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 5.00586L7.4641 0.642226H0.535898L4 5.00586Z" fill="#575D64" />
    </svg>
  );
};

export default Triangle;
