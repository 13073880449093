import React from "react";
import PropTypes from "prop-types";
import StyledCheckbox from "../../../../../components/StyledCheckbox/StyledCheckbox";

const propTypes = {
  t: PropTypes.func.isRequired,
  rules: PropTypes.object,
  setHouseRules: PropTypes.func.isRequired,
};

const HouseRulesFilter = ({ t, rules, setHouseRules }) => {
  const toggleHouseRule = (rule) => {
    setHouseRules({ ...rules, [rule]: !rules[rule] });
  };

  return (
    <div className="HouseRulesFilter">
      <h3 className="HouseRulesFilter-heading">
        {t("pages.contract.landlordReview.rules.houseRules")}
      </h3>
      <div className="HouseRulesFilter-checkboxOuter">
        <input name="petRules" type="hidden" value={rules.petsAllowed} />
        <StyledCheckbox
          label={t("pages.contract.landlordReview.petsAllowed")}
          checked={rules?.petsAllowed}
          onChange={() => toggleHouseRule("petsAllowed")}
          data-testid="HouseRulesFilter-petsAllowed"
        />
      </div>
      <div className="HouseRulesFilter-checkboxOuter">
        <input name="smokingRules" type="hidden" value={rules.smokingAllowed} />
        <StyledCheckbox
          label={t("pages.contract.landlordReview.smokingAllowed")}
          shape="circle"
          checked={rules?.smokingAllowed}
          onChange={() => toggleHouseRule("smokingAllowed")}
          data-testid="HouseRulesFilter-smokingAllowed"
        />
      </div>
    </div>
  );
};

HouseRulesFilter.propTypes = propTypes;

export default HouseRulesFilter;
