import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.node,
  t: PropTypes.func.isRequired,
};

class MapErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error) {
    if (window.rollbar) {
      window.rollbar.error(error);
    }
  }

  render() {
    const { t } = this.props;

    if (this.state.hasError) {
      return (
        <div className="Listings-map">
          <p className="ListingsMap-mapUnavailable">{t("mapUnavailable")}</p>
        </div>
      );
    }
    return this.props.children;
  }
}

MapErrorBoundary.propTypes = propTypes;

export default MapErrorBoundary;
