import React, { Fragment, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import * as amplitude from "@amplitude/analytics-browser";
import omit from "lodash/omit";
import { actions } from "./constants";
import MainLayout from "./MainLayout";
import FederalStateSelection from "./Steps/FederalStateSelection";
import MunicipalitySelection from "./Steps/MunicipalitySelection";
import RegistrationCheckStep from "./Steps/RegistrationCheckStep";
import RegistrationWarningStep from "./Steps/RegistrationWarningStep";

const STEPS = {
  RegistrationCheckStep,
  RegistrationWarningStep,
  FederalStateSelection,
  MunicipalitySelection,
};

const REGISTERED_FLOW_STEPS = Object.getOwnPropertyNames(
  omit(STEPS, ["RegistrationWarningStep"]),
);

const NOT_REGISTERED_FLOW_STEPS = Object.getOwnPropertyNames(
  omit(STEPS, ["FederalStateSelection", "MunicipalitySelection"]),
);

const reducer = (state, action) => {
  switch (action.type) {
    case actions.REGISTRATION_CHECK: {
      return {
        ...state,
        current: "RegistrationCheckStep",
        history: [...state.history, state.current],
      };
    }

    case actions.USER_NOT_REGISTERED: {
      return {
        ...state,
        current: "RegistrationWarningStep",
        history: [...state.history, state.current],
        stepsInFlow: NOT_REGISTERED_FLOW_STEPS,
      };
    }

    case actions.USER_REGISTERED: {
      return {
        ...state,
        current: "FederalStateSelection",
        history: [...state.history, state.current],
        stepsInFlow: REGISTERED_FLOW_STEPS,
      };
    }

    case actions.SELECT_FEDERAL_STATE: {
      return {
        ...state,
        data: {
          ...state.data,
          federalState: action.federalState,
          municipality: action.municipality || null,
          listOfMunicipalities: action.listOfMunicipalities,
        },

        current: "MunicipalitySelection",
        history: [...state.history, state.current],
      };
    }

    case actions.SELECT_MUNICIPALITY: {
      return {
        ...state,
        data: { ...state.data, municipality: action.municipality },
      };
    }

    case actions.GO_TO_PREVIOUS_STEP: {
      return {
        ...state,
        current: state.history[state.history.length - 1],
        history: [...state.history.slice(0, state.history.length - 1)],
      };
    }

    case actions.REOPEN_MODAL_WITH_PREVIOUS_SELECTIONS: {
      return {
        current: "FederalStateSelection",
        history: [state.current],
        stepsInFlow: REGISTERED_FLOW_STEPS,
        data: {
          federalState: action.federalState,
          municipality: action.municipality,
        },
      };
    }

    default: {
      return state;
    }
  }
};

const RefugeeDistributionModal = ({
  municipality,
  federalState,
  applyRefugeeTeritorialSearch,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    current: "RegistrationCheckStep",
    history: [],
    data: { federalState: null, municipality: null },
    stepsInFlow: Object.getOwnPropertyNames(STEPS), // In order to find correct index of active step, we should only use the steps that are actually used in a specific flow
  });

  const isStepActive = (step) => step === state.current;

  const activeStepIndex = state.stepsInFlow.findIndex(isStepActive) + 1;

  // given that step number is not equal for every flow & on the first step we don't know yet how many steps will be there
  // we show progress as 33% by default
  const calculatedProgress =
    activeStepIndex === 1
      ? 33
      : (activeStepIndex / state.stepsInFlow.length) * 100;
  const currentStep = state.current;

  useEffect(() => {
    /** We only support selecting a) just federal state or b) federal state and a municipality within it.
     * If just municipality is defined, that's not a valid usecase. */
    if (federalState || (federalState && municipality)) {
      dispatch({
        type: actions.REOPEN_MODAL_WITH_PREVIOUS_SELECTIONS,
        federalState,
        municipality,
      });
    }
  }, [municipality, federalState]);

  useEffect(() => {
    amplitude.track("Refugee Flow Step Viewed", { step_name: currentStep });
  }, [currentStep]);

  const CurrentStepComponent = STEPS[currentStep];

  return (
    <Fragment>
      <MainLayout
        dispatch={dispatch}
        history={state.history}
        calculatedProgress={calculatedProgress}
      >
        <CurrentStepComponent
          dispatch={dispatch}
          data={state.data}
          currentStep={state.current}
          applyRefugeeTeritorialSearch={applyRefugeeTeritorialSearch}
        />
      </MainLayout>
    </Fragment>
  );
};

RefugeeDistributionModal.propTypes = {
  federalState: PropTypes.string,
  municipality: PropTypes.string,
  applyRefugeeTeritorialSearch: PropTypes.func,
};

export default RefugeeDistributionModal;
