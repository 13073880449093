import "rheostat/initialize";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import algorithm, {
  MAX_PRICE_CAP,
} from "../../../../../../components/Slider/algorithm";
import Slider from "../../../../../../components/Slider/Slider";
import { isNumber } from "../../../../../../utils/validation";
import { useApi } from "../../../../context/ApiContext";
import { useCurrentBbox } from "../../../../context/CurrentBboxContext";
import { useI18n } from "../../../../context/I18nContext";

const propTypes = {
  minPrice: PropTypes.number,
  maxPrice: PropTypes.number,
  isNoDepositFilterOn: PropTypes.bool,
  regionSlug: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.func,
  query: PropTypes.object,
  LoadingComponent: PropTypes.func,
};

const transformHistogramData = (histogramData) => {
  return {
    priceMin: histogramData.priceMin / 100,
    priceMax: histogramData.priceMax / 100,
    countMax: histogramData.countMax,
    priceHistogram: histogramData.priceHistogram.map((item) => ({
      ...item,
      price: item.price / 100,
    })),
    showHistogram:
      histogramData.priceHistogram.filter((item) => item.count > 0).length > 0,
  };
};

const normalizeValue = (value) => {
  if (value === "") {
    return "";
  }
  return Number(value.replace(/[^\d]+/g, ""));
};

const PriceFilterContainer = ({
  minPrice = 0,
  maxPrice = MAX_PRICE_CAP,
  isNoDepositFilterOn = false,
  regionSlug,
  className,
  children,
  query,
  LoadingComponent,
}) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [histogramData, setHistogramData] = useState({});
  const [rangeMin, setRangeMin] = useState(minPrice);
  const [rangeMax, setRangeMax] = useState(maxPrice);
  const [noDeposit, setNoDeposit] = useState(isNoDepositFilterOn);
  const [histogramAlgorithm, setHistogramAlgorithm] = useState(algorithm());
  const [isDisabled, setIsDisabled] = useState(false);
  const api = useApi();
  const { t } = useI18n();
  const { bbox } = useCurrentBbox();
  const currentRegion = bbox || regionSlug;

  const histogramDataRef = useRef(histogramData);
  const rangeMinRef = useRef(rangeMin);
  const rangeMaxRef = useRef(rangeMax);

  useEffect(() => {
    rangeMinRef.current = rangeMin;
  }, [rangeMin]);

  useEffect(() => {
    rangeMaxRef.current = rangeMax;
  }, [rangeMax]);

  useEffect(() => {
    histogramDataRef.current = histogramData;
  }, [histogramData]);

  useEffect(() => {
    if (minPrice < 0 && maxPrice < 0) {
      setRangeMin(histogramDataRef.current.priceMin);
      setRangeMax(histogramDataRef.current.priceMax);
    }
  }, [minPrice, maxPrice]);
  useEffect(() => {
    api.listings
      .getPriceHistogram(currentRegion, query)
      .then((res) => {
        const transformedData = transformHistogramData(res);
        setHistogramAlgorithm(algorithm(transformedData.priceHistogram));
        setHistogramData(transformedData);
        setRangeMin(
          !rangeMinRef.current || rangeMinRef.current < transformedData.priceMin
            ? transformedData.priceMin
            : rangeMinRef.current,
        );
        setRangeMax(
          !rangeMaxRef.current ||
            rangeMaxRef.current > transformedData.priceMax ||
            rangeMaxRef.current === 0
            ? `${MAX_PRICE_CAP}+`
            : rangeMaxRef.current,
        );
        setIsDataLoaded(true);
      })
      .catch(() => {
        setRangeMin(0);
        setRangeMax(0);
        setHistogramData({
          countMax: 0,
          priceMin: 0,
          priceMax: 0,
          showHistogram: false,
        });
        setIsDisabled(true);
        setIsDataLoaded(true);
      });
  }, [api.listings, currentRegion, query]);

  useEffect(() => {
    setNoDeposit(isNoDepositFilterOn);
  }, [isNoDepositFilterOn]);

  const { priceMin, priceMax } = histogramData;

  const onChangeSlider = (event) => {
    const { values } = event;
    setRangeMin(values[0]);
    setRangeMax(values[1]);
  };

  const resetValues = () => {
    setRangeMin(priceMin);
    setRangeMax(`${MAX_PRICE_CAP}+`);
    setNoDeposit(false);
  };

  const onBlurMinValidation = () => {
    if (
      !isNumber(rangeMin) ||
      rangeMin < priceMin ||
      rangeMin >= MAX_PRICE_CAP
    ) {
      setRangeMin(priceMin);
    }
  };

  const onBlurMaxValidation = () => {
    if (
      !isNumber(rangeMax) ||
      rangeMax <= rangeMin ||
      rangeMax >= MAX_PRICE_CAP
    ) {
      setRangeMax(`${MAX_PRICE_CAP}+`);
    }
  };

  const onBlurValidation = (event) => {
    const inputName = event.target.name;
    if (!inputName || inputName === "") {
      return;
    }

    if (inputName.toLowerCase().includes("min")) {
      onBlurMinValidation();
    }

    if (inputName.toLowerCase().includes("max")) {
      onBlurMaxValidation();
    }
  };

  const setMinValue = (stringValue) => setRangeMin(normalizeValue(stringValue));
  const setMaxValue = (stringValue) => setRangeMax(normalizeValue(stringValue));

  return (
    <div className={className}>
      {!isDataLoaded && LoadingComponent && <LoadingComponent />}
      {isDataLoaded && (
        <React.Fragment>
          <Slider
            disabled={isDisabled}
            showHistogram={!!histogramData?.showHistogram}
            algorithm={histogramAlgorithm}
            maxCount={histogramData.countMax}
            currentMinValue={isNumber(rangeMin) ? Number(rangeMin) : priceMin}
            currentMaxValue={isNumber(rangeMax) ? Number(rangeMax) : priceMax}
            histogramRange={histogramData.priceHistogram}
            values={[
              isNumber(rangeMin) ? Number(rangeMin) : priceMin,
              isNumber(rangeMax) ? Number(rangeMax) : priceMax,
            ]}
            onChange={onChangeSlider}
            maxValue={priceMax}
            minValue={priceMin}
          />
          {children(
            rangeMin,
            rangeMax,
            noDeposit,
            t,
            setMinValue,
            setMaxValue,
            setNoDeposit,
            resetValues,
            onBlurValidation,
          )}
        </React.Fragment>
      )}
    </div>
  );
};

PriceFilterContainer.propTypes = propTypes;

export default PriceFilterContainer;
