import dataLayer from "../data-layer";

const pushToDataLayer = (hasOwnShortlist, user) => {
  dataLayer.pushToDataLayer(
    dataLayer.initialDataLayer("shortlist", {
      shortlist: hasOwnShortlist ? "yes" : "no",
      user,
    }),
  );
};

export default pushToDataLayer;
