import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import webSafeFonts from "./webSafeFonts";

const propTypes = {
  fontName: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  weight: PropTypes.number,
  onLoad: PropTypes.func,
  style: PropTypes.object,
};

export const localFonts = [
  "bangers",
  "boogaloo",
  "chewy",
  "cinzel decorative",
  "oleo script",
  "source code pro",
  "vesper libre",
];

const GoogleFont = ({
  fontName,
  weight = 700,
  children: text,
  style,
  onLoad,
  className,
  ...props
}) => {
  const htmlElementRef = React.useRef(null);
  const [selectedFont, setSelectedFont] = useState(fontName);

  useEffect(() => {
    const isWebSafeFont = webSafeFonts.includes(fontName);

    if (fontName && localFonts.includes(fontName.toLowerCase())) {
      setSelectedFont(fontName.toLowerCase());
    }

    // We currently preload and use Poppins on our website so we don't need to load it like the other custom fonts
    if (fontName && fontName === "Poppins") {
      setSelectedFont(fontName);
    }

    if (
      fontName &&
      !isWebSafeFont &&
      fontName !== "Poppins" &&
      !localFonts.includes(fontName.toLowerCase())
    ) {
      setSelectedFont(localFonts[Math.ceil(Math.random() * 5)]);
    }
  }, [fontName]);

  useEffect(() => {
    if (!selectedFont || typeof text !== "string") {
      return;
    }

    if (webSafeFonts.includes(selectedFont) || selectedFont === "Poppins") {
      onLoad();
      return;
    }

    const fontSize = window.getComputedStyle(htmlElementRef.current)[
      "font-size"
    ];

    const styleTag = document.createElement("style");
    const selectedFontFileName = selectedFont.toLowerCase();

    styleTag.append(`
      @font-face {
        font-family: '${selectedFont}, sans-serif';
        font-style: normal;
        font-weight: ${weight};
        src: url('/public/fonts/categories-page/${selectedFontFileName}.eot'); /* IE9 Compat Modes */
        src: local(''),
            url('/public/fonts/categories-page/${selectedFontFileName}.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('/public/fonts/categories-page/${selectedFontFileName}.woff2') format('woff2'), /* Super Modern Browsers */
            url('/public/fonts/categories-page/${selectedFontFileName}.woff') format('woff'), /* Modern Browsers */
            url('/public/fonts/categories-page/${selectedFontFileName}.ttf') format('truetype'), /* Safari, Android, iOS */
            url('/public/fonts/categories-page/${selectedFontFileName}.svg#${selectedFont}') format('svg'); /* Legacy iOS */
      }
  `);
    document.head.appendChild(styleTag);

    if (onLoad) {
      /**
       * I have tried to use the document.fonts.load or the document.fonts.ready
       * method. However both of them simply did not work properly in Firefox and Chrome.
       * Events would be triggered signaling that the font is loaded when it was
       * not, causing the text to be loaded with the wrong font and then re-rendered
       * with correct one.
       */
      let fontLoaded = false;
      let checkFontLoadedInterval = setInterval(() => {
        fontLoaded =
          document.fonts && document.fonts.check(`${fontSize} ${selectedFont}`);
        if (fontLoaded) {
          onLoad();
          clearInterval(checkFontLoadedInterval);
          checkFontLoadedInterval = null;
        }
      }, 100);

      /**
       * If the font did not load after 3 seconds, better show content with
       * default font rather than no content at all 🤷‍♂️
       */
      setTimeout(() => {
        if (checkFontLoadedInterval) {
          onLoad();
        }
      }, 3000);
    }

    return () => {
      document.head.removeChild(styleTag);
    };
  }, [text, weight, onLoad, selectedFont]);

  if (typeof text !== "string") {
    console.warn("GoogleFont component only accepts string as child");
    return null;
  }

  const sanitizeFontName = (str) => str.replace(/[^a-zA-Z]/g, "");

  return (
    <span
      ref={htmlElementRef}
      style={{
        fontFamily: `'${selectedFont}', sans-serif`, // Fallback font is used if the main font doesn't support Cirillic script for UK and RU
        ...style,
      }}
      className={classNames(className, sanitizeFontName(selectedFont))}
      {...props}
    >
      {text}
    </span>
  );
};

GoogleFont.propTypes = propTypes;
export default GoogleFont;
