import React from "react";
import PropTypes from "prop-types";

const HeaderImage = ({ srcSet, mobileSrcSet, src, alt }) => {
  return (
    <picture>
      <source
        className="CoverImage-image"
        srcSet={srcSet}
        sizes="100vw"
        media="(min-width: 993px)"
      />
      <source
        className="CoverImage-image"
        srcSet={mobileSrcSet}
        sizes="100vw"
        media="(max-width: 992px)"
      />
      <img className="CoverImage-image" src={src} alt={alt} />
    </picture>
  );
};

HeaderImage.propTypes = {
  srcSet: PropTypes.string.isRequired,
  mobileSrcSet: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default HeaderImage;
