import React from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import { Link } from "react-router-dom";

const EmptyStateMessage = ({
  t,
  url,
  regionSlug,
  lang,
  query,
  showRefugeeDistributionFeatures,
  hasCategory,
}) => {
  /** We have 3 different messages we show when there are no search results.
   * One for the regular search results page, one for categories and one for
   * refugee distribution search results.
   */
  let title = t("pages.ListingsPage.noResults.title");
  let body = t("pages.ListingsPage.noResults.text");
  if (showRefugeeDistributionFeatures) {
    title = t("pages.ListingsPage.noResults.refugeeDistribution.title");
    body = t("pages.ListingsPage.noResults.refugeeDistribution.text");
  } else if (hasCategory) {
    title = t("pages.CategoriesPage.noResults.title");
    const parentPageUrl = url(
      "listinglist",
      { region: regionSlug, lang },
      { query: omit(query, ["labels"]) },
    );

    const message = t("pages.CategoriesPage.noResults.text", {
      parentPageLink: "#",
    });
    const parentPageLink = (
      <Link to={parentPageUrl}>
        {t("pages.CategoriesPage.noResults.linkText")}
      </Link>
    );

    const subText = message.split("#");
    subText.splice(1, 0, parentPageLink);
    body = (
      <React.Fragment>
        {subText.map((stringOrComponent, i) => (
          <span key={i}>{stringOrComponent}</span>
        ))}
      </React.Fragment>
    );
  }

  return (
    <article
      className="Listings-empty-message"
      data-testid="Listings-empty-message"
    >
      <h3
        className="Listings-empty-message-title"
        data-testid="Listings-empty-message-title"
      >
        {title}
      </h3>
      <p
        className="Listings-empty-message-text"
        data-testid="Listings-empty-message-text"
      >
        {body}
      </p>
    </article>
  );
};

EmptyStateMessage.propTypes = {
  t: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  regionSlug: PropTypes.string.isRequired,
  query: PropTypes.object,
  hasCategory: PropTypes.bool,
  showRefugeeDistributionFeatures: PropTypes.bool,
};

export default EmptyStateMessage;
