import React from "react";
import PropTypes from "prop-types";

const WarningIconInCircle = ({ width = 60, height = 60, ...props }) => {
  return (
    <svg
      data-testid="WarningIconInCircle"
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="30" cy="30" r="29.5" fill="#FAE8E8" stroke="#F5D8D8" />
      <path
        d="M28.268 19c.77-1.333 2.694-1.333 3.464 0l9.526 16.5c.77 1.333-.192 3-1.732 3H20.474c-1.54 0-2.502-1.667-1.732-3L28.268 19Z"
        fill="#fff"
        stroke="#EB5757"
        strokeWidth="2"
      />
      <path
        opacity=".35"
        d="M26.536 18c1.54-2.667 5.388-2.667 6.928 0l9.526 16.5c1.54 2.667-.385 6-3.464 6H20.474c-3.08 0-5.004-3.333-3.464-6L26.536 18Z"
        stroke="#EB5757"
      />
      <path
        d="M30.861 31.944h-1.64l-.233-6.897h2.106l-.233 6.897Zm-.82 1.08c.333 0 .6.098.8.294a.994.994 0 0 1 .307.752c0 .301-.102.55-.307.745-.2.196-.467.294-.8.294-.328 0-.595-.098-.8-.294a.998.998 0 0 1-.3-.745c0-.3.1-.549.3-.745.205-.2.472-.3.8-.3Z"
        fill="#EB5757"
      />
    </svg>
  );
};

WarningIconInCircle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default WarningIconInCircle;
