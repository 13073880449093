import React from "react";
import PropTypes from "prop-types";
import * as amplitude from "@amplitude/analytics-browser";
import { useI18n } from "../../../spages/spa/context/I18nContext";
import HouseInCircleIcon from "../../Icons/HouseInCircleIcon";
import Step from "./Step";

import "./RegistrationWarningStep.scss";

const RegistrationWarningStep = ({
  currentStep,
  applyRefugeeTeritorialSearch,
}) => {
  const { t } = useI18n();

  const stepBody = (
    <React.Fragment>
      <p>
        {t("components.refugeeDistributionModal.toReceiveSocialBenefits")}{" "}
        <a
          href={t("components.refugeeDistributionModal.govWebsiteLink")}
          target="_blank"
          className="RefugeeDistributionModal-registrationLink"
        >
          {t("components.refugeeDistributionModal.officialGovWebsite")}
        </a>
      </p>
      <br />
      <p>{t("components.refugeeDistributionModal.findHomesForRefugees")}</p>
    </React.Fragment>
  );

  return (
    <Step
      stepIcon={<HouseInCircleIcon />}
      stepBody={stepBody}
      stepTitle={t(
        "components.refugeeDistributionModal.placeOfResidenceRequired",
      )}
      onClickPrimaryButton={() => {
        amplitude.track("Refugee Flow Button Clicked", {
          button_description: "Show results across all Germany",
          step_name: currentStep,
        });
        applyRefugeeTeritorialSearch({
          federalState: null,
          municipality: null,
        });
      }}
      primaryButtonLabel={t(
        "components.refugeeDistributionModal.findAvailableHomesNow",
      )}
      primaryButtonDataTestId="RegistrationWarningStep-findHomesNowButton"
      dataTestId="RegistrationWarningStep"
    />
  );
};

RegistrationWarningStep.propTypes = {
  currentStep: PropTypes.string.isRequired,
  applyRefugeeTeritorialSearch: PropTypes.func.isRequired,
};

export default RegistrationWarningStep;
