import React, { useRef } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import DateInput from "../../../../../components/DateInput/DateInput";
import { Button } from "../../../../../components/DesignSystem/Button/Button";

const MobileDateFilter = ({
  t,
  from,
  to,
  minDateFrom,
  minDateTo,
  onChangeFrom,
  onChangeTo,
  hasFilter,
  popOverClassName,
}) => {
  const fromInputRef = useRef();
  const toInputRef = useRef();

  const showDatePickers = () => {
    fromInputRef.current.focus();
  };

  const handleChangeFrom = (isoDateStr) => {
    onChangeFrom(isoDateStr);
    toInputRef.current.focus();
  };

  const formatDate = (date) => {
    return dayjs.utc(date).format("MMM DD");
  };

  return (
    <React.Fragment>
      <Button
        type="button"
        className="ListingFilterButton"
        onClick={showDatePickers}
        colorVariant={!hasFilter ? "white" : "brandPurple"}
      >
        {from && to && `${formatDate(from)} - ${formatDate(to)}`}
        {!from && !to && t("dates")}
        {from && !to && `${t("from")} ${formatDate(from)}`}
      </Button>

      <div className="MobileDateFilter--hidden">
        <DateInput
          t={t}
          name="from"
          mobileLabel={t("moveIn")}
          minDate={minDateFrom}
          onChange={handleChangeFrom}
          value={from}
          ref={fromInputRef}
          popOverClassName={popOverClassName}
        />
        <DateInput
          t={t}
          name="to"
          mobileLabel={t("moveOut")}
          minDate={minDateTo}
          onChange={onChangeTo}
          value={to}
          ref={toInputRef}
          popOverClassName={popOverClassName}
        />
      </div>
    </React.Fragment>
  );
};

MobileDateFilter.propTypes = {
  t: PropTypes.func.isRequired,
  from: PropTypes.string,
  to: PropTypes.string,
  minDateFrom: PropTypes.string.isRequired,
  minDateTo: PropTypes.string.isRequired,
  hasFilter: PropTypes.bool,
  popOverClassName: PropTypes.string,
  onChangeFrom: PropTypes.func.isRequired,
  onChangeTo: PropTypes.func.isRequired,
};

export default MobileDateFilter;
