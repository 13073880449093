import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button } from "../../../../../components/DesignSystem/Button/Button";

const propTypes = {
  t: PropTypes.func.isRequired,
  minSize: PropTypes.number,
  setMinSize: PropTypes.func.isRequired,
};

const APARTMENT_SIZES = {
  cozy: 30,
  comfortable: 60,
  spacious: 90,
};

const ApartmentSizeFilter = ({ t, minSize, setMinSize }) => {
  const toggleApartmentSize = (size) => {
    if (size === minSize) {
      setMinSize(1);
    } else {
      setMinSize(size);
    }
  };

  const isCozy =
    minSize >= APARTMENT_SIZES.cozy && minSize < APARTMENT_SIZES.comfortable;
  const isComfortable =
    minSize >= APARTMENT_SIZES.comfortable &&
    minSize < APARTMENT_SIZES.spacious;
  const isSpacious = minSize >= APARTMENT_SIZES.spacious;

  return (
    <div className="ApartmentSizeFilter">
      <input name="minSize" value={minSize} type="hidden" />
      <div className="ApartmentSizeFilter-title">{t("apartmentSize")}</div>
      <div className="ApartmentSizeFilter-subtitle">
        {t("minimumSizeInMeteresSquared")}
      </div>
      <div className="ApartmentSizeFilter-buttons">
        <div className="ApartmentSizeFilter-sizeBtn">
          <Button
            colorVariant={isCozy ? "brandPurple" : "secondary"}
            type="button"
            onClick={() => toggleApartmentSize(APARTMENT_SIZES.cozy)}
            size="medium"
            className="ApartmentSizeFilter-btn"
          >
            {`${APARTMENT_SIZES.cozy}+`}
          </Button>
          <div
            className={classnames("ApartmentSizeFilter-caption", {
              "ApartmentSizeFilter-caption--focus": isCozy,
            })}
          >
            {t("cozy")}
          </div>
        </div>
        <div className="ApartmentSizeFilter-sizeBtn">
          <Button
            type="button"
            colorVariant={isComfortable ? "brandPurple" : "secondary"}
            onClick={() => toggleApartmentSize(APARTMENT_SIZES.comfortable)}
            size="medium"
            className="ApartmentSizeFilter-btn"
          >
            {`${APARTMENT_SIZES.comfortable}+`}
          </Button>
          <div
            className={classnames("ApartmentSizeFilter-caption", {
              "ApartmentSizeFilter-caption--focus": isComfortable,
            })}
          >
            {t("comfortable")}
          </div>
        </div>
        <div className="ApartmentSizeFilter-sizeBtn">
          <Button
            type="button"
            colorVariant={isSpacious ? "brandPurple" : "secondary"}
            onClick={() => toggleApartmentSize(APARTMENT_SIZES.spacious)}
            size="medium"
            className="ApartmentSizeFilter-btn"
          >
            {`${APARTMENT_SIZES.spacious}+`}
          </Button>
          <div
            className={classnames("ApartmentSizeFilter-caption", {
              "ApartmentSizeFilter-caption--focus": isSpacious,
            })}
          >
            {t("spacious")}
          </div>
        </div>
      </div>
    </div>
  );
};

ApartmentSizeFilter.propTypes = propTypes;

export default ApartmentSizeFilter;
