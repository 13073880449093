import { useEffect, useState } from "react";

function usePreviousValue(value) {
  const [prevValue, setPrevValue] = useState(value);

  useEffect(() => {
    return () => {
      setPrevValue(value);
    };
  }, [value]);

  return prevValue;
}

export default usePreviousValue;
