import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  constructBasicSrcset,
  getAllImageUrls,
} from "../../utils/wunderflat-image";
import { DesktopCoverImage, DesktopImage } from "./DesktopImages";
import GalleryNavigation from "./GalleryNavigation";
import { MobileCoverImage, MobileImage } from "./MobileImages";

const Images = React.forwardRef(
  (
    {
      images,
      touch,
      imagesRefs,
      selectedIndex,
      cover,
      src,
      srcSet,
      coverSrc,
      coverSrcSet,
      setSelectedIndex,
      sectionLabel,
      selectNext,
      selectPrevious,
      sizes,
      coverImageLoadingStrategy,
    },
    ref,
  ) => {
    if (images?.length < 1) {
      return null;
    }

    if (touch)
      return (
        <React.Fragment>
          <div
            ref={ref}
            className={classNames("ImageGallery-container", {
              "ImageGallery-container--touch": touch,
            })}
            onTouchEnd={() => {
              const containerX = ref?.current?.getBoundingClientRect().x;
              const containerWidth = ref?.current?.offsetWidth;
              // We need to wait for animation before we check for the selected image
              setTimeout(() => {
                const index = imagesRefs.findIndex((image) => {
                  const imageX = image?.current?.getBoundingClientRect().x;
                  return Math.abs(imageX) - containerX < containerWidth / 2;
                });
                if (index >= 0 && index !== selectedIndex) {
                  setSelectedIndex(index);
                }
              }, 150);
            }}
          >
            {images?.map((img, i) => {
              const imageUrls = getAllImageUrls(img);
              const { src, srcSet } = constructBasicSrcset(imageUrls);

              return (
                <React.Fragment key={img.imageId}>
                  {cover ? (
                    <MobileCoverImage
                      index={i}
                      src={src}
                      srcSet={srcSet}
                      touch={touch}
                      imagesRefs={imagesRefs}
                      imageLoadingStrategy={coverImageLoadingStrategy}
                    />
                  ) : (
                    <MobileImage
                      index={i}
                      src={src}
                      srcSet={srcSet}
                      sectionLabel={sectionLabel}
                      touch={touch}
                      imagesRefs={imagesRefs}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </div>
          <GalleryNavigation
            images={images}
            selectNext={selectNext}
            selectPrevious={selectPrevious}
          />
        </React.Fragment>
      );

    if (cover)
      return (
        <DesktopCoverImage
          ref={ref}
          src={coverSrc}
          srcSet={coverSrcSet}
          sizes={sizes}
          selectNext={selectNext}
          selectPrevious={selectPrevious}
          images={images}
          imageLoadingStrategy={coverImageLoadingStrategy}
        />
      );

    return (
      <DesktopImage
        ref={ref}
        src={src}
        srcSet={srcSet}
        sizes={sizes}
        selectNext={selectNext}
        selectPrevious={selectPrevious}
        sectionLabel={sectionLabel}
        images={images}
      />
    );
  },
);

Images.propTypes = {
  src: PropTypes.string,
  srcSet: PropTypes.string,
  coverSrc: PropTypes.string,
  coverSrcSet: PropTypes.string,
  touch: PropTypes.bool,
  cover: PropTypes.bool,
  setSelectedIndex: PropTypes.func,
  selectedIndex: PropTypes.number,
  images: PropTypes.array,
  imagesRefs: PropTypes.array,
  sectionLabel: PropTypes.string,
  selectNext: PropTypes.func,
  selectPrevious: PropTypes.func,
  sizes: PropTypes.string,
  coverImageLoadingStrategy: PropTypes.oneOf(["lazy", "eager"]),
};

export default Images;
