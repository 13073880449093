import React, { Fragment } from "react";
import PropTypes, { bool, func, object, shape, string } from "prop-types";
import classNames from "classnames";
import AppHeader from "../../../../../components/AppHeader/AppHeader";
import GeocodingSearch from "../../../../../components/GeocodingSearch/GeocodingSearch";
import StickyHeader from "../../../../../components/StickyHeader/StickyHeader";
import ListingFilter from "../ListingFilter/ListingFilter";

const Header = ({
  translationUrls,
  lang,
  t,
  user,
  regionSlug,
  capitalizedRegionSlug,
  onChangeRegion,
  isSmallScreen,
  query,
  onChangeFilter,
  isMapView,
  switchToMapView,
  switchToListView,
  updateURL,
  logoProps,
  currentUrl,
  analyticsEvent,
  searchBias,
  url,
  regionBbox,
}) => {
  const region = {
    label: capitalizedRegionSlug,
    value: regionSlug,
  };

  return (
    <Fragment>
      <AppHeader
        lang={lang}
        t={t}
        user={user}
        logoProps={logoProps}
        currentUrl={currentUrl}
        translationUrls={translationUrls}
        theme="white"
        border={false}
        analyticsEvent={analyticsEvent}
      >
        <GeocodingSearch
          className="HeaderInputSearch"
          lang={lang}
          bias={searchBias}
          value={region.label}
          onSelectSuggestion={onChangeRegion}
          placeholder={t("components.GeocodingInputSearch.placeholder")}
        />
      </AppHeader>

      <div className="Listings-bar">
        <ListingFilter
          t={t}
          isSmallScreen={isSmallScreen}
          onChange={onChangeFilter}
          updateURL={updateURL}
          regionSlug={regionSlug}
          query={query}
          lang={lang}
          analyticsEvent={analyticsEvent}
          url={url}
          currentUrl={currentUrl}
          regionBbox={regionBbox}
          isUserLoggedIn={!!user}
        />

        {!isSmallScreen && (
          <span className="Listings-viewSelector">
            <button
              className={classNames(
                "Listings-viewListButton",
                !isMapView && "Listings-viewListButton--active",
              )}
              onClick={switchToListView}
            >
              {t("list")}
            </button>
            <button
              className={classNames(
                "Listings-viewMapButton",
                isMapView && "Listings-viewMapButton--active",
              )}
              onClick={switchToMapView}
            >
              {t("map")}
            </button>
          </span>
        )}
      </div>
    </Fragment>
  );
};

Header.propTypes = {
  t: func.isRequired,
  lang: string.isRequired,
  user: object,
  regionSlug: string.isRequired,
  onChangeRegion: func.isRequired,
  onChangeFilter: func.isRequired,
  isSmallScreen: bool.isRequired,
  query: object,
  isMapView: bool.isRequired,
  switchToMapView: func.isRequired,
  switchToListView: func.isRequired,
  updateURL: func.isRequired,
  logoProps: object,
  currentUrl: string,
  analyticsEvent: func.isRequired,
  translationUrls: shape({
    en: string.isRequired,
    de: string.isRequired,
  }).isRequired,
  searchBias: PropTypes.string,
  capitalizedRegionSlug: PropTypes.string,
  url: PropTypes.func.isRequired,
  regionBbox: PropTypes.string,
};

/* eslint-disable new-cap */
export default StickyHeader(Header);
