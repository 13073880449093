import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import omit from "lodash/omit";
import { Link } from "react-router-dom";
import "./BreadcrumbsNav.scss";

const propTypes = {
  url: PropTypes.func.isRequired,
  region: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  cityName: PropTypes.string,
  analyticsEvent: PropTypes.func.isRequired,
  analyticsEventData: PropTypes.shape({
    action: PropTypes.string.isRequired,
  }),
  category: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  ),
  query: PropTypes.object,
};

const BreadcrumbsNav = ({
  url,
  region,
  lang,
  cityName,
  category,
  categories,
  analyticsEvent,
  analyticsEventData,
  query,
}) => {
  const [showCategoriesList, setShowCategoriesList] = useState(false);
  const categoriesListRef = useRef();

  const toggleShowCategoriesList = () => {
    setShowCategoriesList(!showCategoriesList);
  };

  const onClickOutsideCategoriesListHandler = useCallback(
    (e) => {
      if (!categoriesListRef.current.contains(e.target) && showCategoriesList) {
        setShowCategoriesList(false);
      }
    },
    [showCategoriesList],
  );

  const trackListingCollectionChange = () => {
    analyticsEvent(analyticsEventData.action);
  };

  useEffect(() => {
    window.addEventListener("click", onClickOutsideCategoriesListHandler);

    return () => {
      window.removeEventListener("click", onClickOutsideCategoriesListHandler);
    };
  }, [onClickOutsideCategoriesListHandler]);

  return (
    <nav className="BreadcrumbsNav" data-testid="BreadcrumbsNav">
      <Link
        to={url(
          "listinglist",
          {
            region,
            lang,
          },
          { query: omit(query, ["labels"]) },
        )}
        className="BreadcrumbsNav-city"
      >
        {`${cityName} / `}
      </Link>
      <div className="BreadcrumbsNav-category">
        <button
          className="BreadcrumbsNav-category-selected"
          data-testid="BreadcrumbsNav-showCategoryListButton"
          onClick={toggleShowCategoriesList}
          ref={categoriesListRef}
        >
          <span>{`${category.title}`}</span>
          <span className="BreadcrumbsNav-category-selected-toggleContainer">
            <span
              className={classNames(
                "BreadcrumbsNav-category-selected-toggleContainer-arrow",
                {
                  "BreadcrumbsNav-category-selected-toggleContainer-arrow--rotate":
                    showCategoriesList,
                },
              )}
            />
          </span>
        </button>
        <ul
          className={classNames("BreadcrumbsNav-category-list", {
            "BreadcrumbsNav-category-list--isOpen": showCategoriesList,
          })}
          data-testid="BreadcrumbsNav-categoryList"
        >
          {categories
            .filter((cat) => cat.title !== category.title)
            .map((cat) => {
              return (
                <li
                  key={cat.slug}
                  data-testid={`BreadcrumbsNav-category-${cat.slug}`}
                >
                  <Link
                    className={classNames(
                      "BreadcrumbsNav-category-list-category",
                      {
                        "BreadcrumbsNav-category-list--selected":
                          category.title === cat.title, // current OR selected category
                      },
                    )}
                    to={url(
                      "categories",
                      {
                        region,
                        category: cat.slug,
                        lang,
                      },
                      { query },
                    )}
                    onClick={() => {
                      trackListingCollectionChange();
                    }}
                  >
                    {cat.title}
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
    </nav>
  );
};

BreadcrumbsNav.propTypes = propTypes;
export default BreadcrumbsNav;
