import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import SvgSpinner from "../SvgSpinner/SvgSpinner";
import { setImageRefLoading } from "./utils";

const ThumbnailImage = React.forwardRef(({ src }, ref) => {
  const [loading, setLoading] = useState(false);
  return (
    <div ref={ref} className="ImageGallery-galleryImage">
      {loading && (
        <div className="ImageGallery-loader">
          <SvgSpinner />
        </div>
      )}
      <img ref={(img) => setImageRefLoading(img, setLoading)} src={src} />
    </div>
  );
});
ThumbnailImage.propTypes = {
  src: PropTypes.string,
};

const Thumbnails = React.forwardRef(
  ({ showThumbnails, onClick, selectedIndex, images, thumbnailsRefs }, ref) =>
    showThumbnails ? (
      <div ref={ref} className="ImageGallery-thumbnails">
        {images?.map((image, i) => (
          <span
            key={image.imageId}
            className={classNames("ImageGallery-thumbnail", {
              "ImageGallery-thumbnail--active": i === selectedIndex,
            })}
            onClick={() => onClick(i)}
          >
            <ThumbnailImage
              ref={thumbnailsRefs[i]}
              src={image.urls.thumbnail}
            />
          </span>
        ))}
      </div>
    ) : null,
);

Thumbnails.propTypes = {
  showThumbnails: PropTypes.bool,
  onClick: PropTypes.func,
  selectedIndex: PropTypes.number,
  images: PropTypes.array,
  thumbnailsRefs: PropTypes.array,
};

export default Thumbnails;
