import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import dayjs from "dayjs";
import { isChild } from "../../utils/dom";

const isNumber = (input) => /^\d*$/.test(input);
const maxDays = {
  1: 31, // January
  2: 29, // February
  3: 31, // March
  4: 30, // April
  5: 31, // May
  6: 30, // June
  7: 31, // July
  8: 31, // August
  9: 30, // September
  10: 31, // October
  11: 30, // November
  12: 30, // December
};

class DateTextInput extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    jumbo: PropTypes.bool,
    borderless: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    className: PropTypes.string,
    lang: PropTypes.oneOf(["de", "en"]),
  };

  constructor(props) {
    super();

    this.onChangeYear = this.onChangeYear.bind(this);
    this.onChangeMonth = this.onChangeMonth.bind(this);
    this.onChangeDay = this.onChangeDay.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onClick = this.onClick.bind(this);
    this.focus = this.focus.bind(this);
    this.state = this.stateFromProps(props, true);

    this.dayInputRef = React.createRef();
    this.monthInputRef = React.createRef();
    this.yearInputRef = React.createRef();
  }

  componentDidMount() {
    this.resizeInputs();
    if (!this.props.readOnly) {
      document.addEventListener("textInput", this.onTextInput);
    }
  }

  // TODO: https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillReceiveProps(props) {
    // if value is not set, component is not controlled
    if (typeof props.value === "undefined") return;
    const value = this.state.value ? this.state.value.format("YYYY-MM-DD") : "";

    // if value hasn't changed, do nothing
    if (props.value === value) return;

    this.setState(this.stateFromProps(props), () => {
      this.resizeInputs();
    });
  }

  // TODO: https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.resizeInputs();
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
    document.removeEventListener("textInput", this.onTextInput);
  }

  onTextInput = (e) => {
    if (e.data !== "." || this.unmounted) {
      return;
    }

    const dayInput = this.dayInputRef?.current;
    const monthInput = this.monthInputRef?.current;
    const yearInput = this.yearInputRef?.current;

    if (document.activeElement === dayInput) {
      e.preventDefault();
      monthInput?.focus();
      return monthInput?.select();
    }

    if (document.activeElement === monthInput) {
      e.preventDefault();
      yearInput?.focus();
      return yearInput?.select();
    }
  };

  onClick() {
    if (this.state.focused) return;
    this.focus();
  }

  onFocus() {
    if (this.state.focused) {
      return;
    }

    this.setState({ focused: true });
    if (!this.props.onFocus) return;
    this.props.onFocus();
  }

  onBlur() {
    setTimeout(() => {
      if (this.unmounted) return;
      const focused = document.activeElement;
      const dateTextInput = ReactDOM.findDOMNode(this);

      if (!isChild(dateTextInput, focused)) {
        this.setState({ focused: false });

        if (!this.props.onBlur) return;
        this.props.onBlur();
      }
    });
  }

  onChangeYear(e) {
    const year = e.target.value.trim();
    if (!this.isValidYear(year)) return;
    this.setStateAndUpdate({ year });
    this.resizeInputs();
  }

  onChangeMonth(e) {
    const month = e.target.value.trim();
    if (!this.isValidMonth(month)) return;
    this.setStateAndUpdate({ month });
    this.resizeInputs();
  }

  onChangeDay(e) {
    const day = e.target.value.trim();

    if (!this.isValidDay(day)) return;
    this.setStateAndUpdate({ day });
    this.resizeInputs();
  }

  setStateAndUpdate(state) {
    const newState = Object.assign({}, this.state, state);
    const { year, month, day } = newState;

    const value =
      year && year.length === 4 && month && day && Number(day) !== 0
        ? dayjs.utc([year, Number(month), day].join("-"))
        : null;

    newState.value = value && value.isValid() ? value : null;

    if (this.state.value !== newState.value && this.props.onChange) {
      const newValue = newState.value
        ? newState.value.format("YYYY-MM-DD")
        : "";
      return this.setState(newState, () => this.props.onChange(newValue));
    }

    this.setState(newState);
  }

  resizeInputs() {
    const inputRefs = [this.dayInputRef, this.monthInputRef, this.yearInputRef];
    const fontAttrs = ["fontFamily", "fontSize", "fontWeight"];
    const shadow = document.createElement("span");
    shadow.style.position = "absolute";
    shadow.style.top = "-999px";
    document.body.appendChild(shadow);
    inputRefs.forEach((inputRef) => {
      const input = inputRef?.current;

      if (input) {
        const styles = window.getComputedStyle(input);
        fontAttrs.forEach((prop) => (shadow.style[prop] = styles[prop]));
        shadow.innerHTML = input.value || input.getAttribute("placeholder");
        input.style.width = `${shadow.offsetWidth + 1}px`;
      }
    }, this);
    document.body.removeChild(shadow);
  }

  isValidYear(year) {
    return isNumber(year);
  }

  isValidMonth(month) {
    const num = Number(month);
    return isNumber(month) && num >= 0 && num <= 12;
  }

  isValidDay(day) {
    const num = Number(day);
    if (!isNumber(day) || num < 0) return false;

    const month = !!this.state.month && Number(this.state.month);

    // month === 0 is falsey
    if (month !== false) {
      const year = this.isValidYear(this.state.year) && Number(this.state.year);
      const referenceDate = year && dayjs.utc([year, month, 1].join("-"));
      return referenceDate
        ? num <= referenceDate.endOf("month").date()
        : num <= maxDays[month];
    }

    return num <= 31;
  }

  stateFromProps(props, initial) {
    if (initial && props.defaultValue) {
      const value = dayjs.utc(props.defaultValue);

      return {
        value,
        year: value.format("YYYY"),
        month: value.format("MM"),
        day: value.format("DD"),
      };
    }

    if (!props.value) {
      return {
        value: null,
        year: "",
        month: "",
        day: "",
      };
    }

    const value = dayjs.utc(props.value);

    return {
      value,
      year: value.format("YYYY"),
      month: value.format("MM"),
      day: value.format("DD"),
    };
  }

  pad(dimension) {
    const value = this.state[dimension];
    if (!value) return;
    const state = {};
    state[dimension] = value.toString().padStart(2, "0");
    this.setState(state, () => {
      this.resizeInputs();
    });
  }

  focus(opts) {
    this.dayInputRef?.current?.focus(opts);
  }

  scrollIntoView(opts) {
    this.dayInputRef?.current?.scrollIntoView(opts);
  }

  blur() {
    const dateTextInput = ReactDOM.findDOMNode(this);
    const focused = document.activeElement;
    if (!isChild(dateTextInput, focused)) return;
    focused.blur();
  }

  render() {
    const { value, year, month, day, focused } = this.state;
    const {
      className,
      placeholder,
      disabled,
      label,
      readOnly,
      fullWidth,
      jumbo,
      borderless,
      inlineLabel,
      t,
      lang = "de",
    } = this.props;
    const empty = !(year || month || day);
    const showPlaceholder = empty && placeholder && !focused;
    const dateSeparator = lang === "de" ? "." : "/";

    const inputs = (
      <span
        className={`DateTextInput-inputs ${
          inlineLabel ? "DateTextInput-inputs--inlineLabel" : ""
        }`}
      >
        {inlineLabel && (
          <span className="DateTextInput-inlineLabel">{label}</span>
        )}
        <input
          value={day}
          onChange={this.onChangeDay}
          onBlur={this.pad.bind(this, "day")}
          ref={this.dayInputRef}
          placeholder={t("components.DateTextInput.placeholder.day")}
          maxLength="2"
          autoComplete="off"
          className="DateTextInput-day"
          data-testid="DateTextInput-day"
          readOnly={readOnly}
          disabled={disabled}
          onFocus={this.blur}
          type="text"
          inputMode="numeric"
        />
        <span className="DateTextInput-seperator">{dateSeparator}</span>
        <input
          value={month}
          onChange={this.onChangeMonth}
          onBlur={this.pad.bind(this, "month")}
          ref={this.monthInputRef}
          placeholder={t("components.DateTextInput.placeholder.month")}
          maxLength="2"
          autoComplete="off"
          className="DateTextInput-month"
          data-testid="DateTextInput-month"
          readOnly={readOnly}
          disabled={disabled}
          onFocus={this.blur}
          type="text"
          inputMode="numeric"
        />
        <span className="DateTextInput-seperator">{dateSeparator}</span>
        <input
          value={year}
          onChange={this.onChangeYear}
          ref={this.yearInputRef}
          placeholder={t("components.DateTextInput.placeholder.year")}
          maxLength="4"
          autoComplete="off"
          className="DateTextInput-year"
          data-testid="DateTextInput-year"
          readOnly={readOnly}
          disabled={disabled}
          onFocus={this.blur}
          type="text"
          inputMode="numeric"
        />
        <input
          type="hidden"
          name={this.props.name}
          value={value ? value.format("YYYY-MM-DD") : ""}
        />
      </span>
    );

    return (
      <span
        onClick={this.onClick}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        className={`DateTextInput${
          showPlaceholder ? " DateTextInput--showingPlaceholder" : ""
        }${label ? " DateTextInput--hasLabel" : ""}${
          disabled ? " DateTextInput--disabled" : ""
        }${focused ? " DateTextInput--focused" : ""}${
          jumbo ? " DateTextInput--jumbo" : ""
        }${borderless ? " DateTextInput--borderless" : ""}${
          fullWidth ? " DateTextInput--fullWidth" : ""
        }${className ? ` ${className}` : ""}`}
        data-testid="DateTextInput"
        data-dateid={`DateTextInput-${this.props.name}`}
      >
        <span
          className={classNames("DateTextInput-placeholder", {
            "DateTextInput-placeholder--hidden": !showPlaceholder,
          })}
        >
          {placeholder}
        </span>

        {label && !inlineLabel ? (
          <label className="DateTextInput-label">
            <span className="DateTextInput-labelText">{label}</span>
            {inputs}
          </label>
        ) : (
          inputs
        )}
      </span>
    );
  }
}

export default DateTextInput;
