import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Icon from "./Icon";

const StyledCheckbox = ({
  "data-testid": dataTestId,
  className,
  dangerouslySetInnerHtmlForLabel = null,
  error = null,
  expand = false,
  label = null,
  labelPosition = "right",
  ...props
}) => {
  return (
    <label
      className={classNames(
        "StyledCheckbox",
        `StyledCheckbox-direction--${labelPosition}`,
        { "StyledCheckbox--expand": expand },
        className,
      )}
      data-testid={dataTestId}
    >
      {/* Visually hidden */}
      <input type="checkbox" className="StyledCheckbox-input" {...props} />

      <div className="StyledCheckbox-iconContainer">
        <Icon />
      </div>

      <p
        className={`StyledCheckbox-text StyledCheckbox-text--${labelPosition}`}
      >
        {dangerouslySetInnerHtmlForLabel ? (
          <span
            key="StyledCheckbox-label"
            className="StyledCheckbox-label"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: dangerouslySetInnerHtmlForLabel,
            }}
          />
        ) : (
          <span key="StyledCheckbox-label" className="StyledCheckbox-label">
            {label}
          </span>
        )}

        {error && <span className="StyledCheckbox-error">{error}</span>}
      </p>
    </label>
  );
};

StyledCheckbox.propTypes = {
  "data-testid": PropTypes.string,
  className: PropTypes.string,
  dangerouslySetInnerHtmlForLabel: PropTypes.string,
  error: PropTypes.string,
  expand: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  labelPosition: PropTypes.string,
};

export default StyledCheckbox;
