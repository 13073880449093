import React from "react";
import EllipsisIcon from "../Icons/EllipsisIcon";
import "./Pagination.scss";

const EllipsisItem = () => (
  <span className="EllipsisItem">
    <EllipsisIcon className="EllipsisItem-icon" />
  </span>
);

export default EllipsisItem;
