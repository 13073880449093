import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import * as amplitude from "@amplitude/analytics-browser";
import { misc } from "@wunderflats/constants";
import { useApi } from "../../../spages/spa/context/ApiContext";
import { useI18n } from "../../../spages/spa/context/I18nContext";
import MarkerIcon from "../../Icons/MarkerIcon";
import { actions } from "../constants";
import Step from "./Step";

const FederalStateSelection = ({
  dispatch,
  data,
  currentStep,
  applyRefugeeTeritorialSearch,
}) => {
  const { t } = useI18n();
  const api = useApi();

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [selectedFederalState, setSelectedFederalState] = useState(
    data.federalState || "",
  );
  const [submissionError, setSubmissionError] = useState();

  const fetchMunicipalities = useCallback(async () => {
    const data =
      await api.municipalities.getByFederalState(selectedFederalState);
    return data;
  }, [api, selectedFederalState]);

  const apply = async () => {
    setIsLoadingData(true);

    await fetchMunicipalities()
      .then((listOfMunicipalities) => {
        dispatch({
          type: actions.SELECT_FEDERAL_STATE,
          federalState: selectedFederalState,
          municipality: data.municipality,
          listOfMunicipalities,
        });
      })
      .catch(() => {
        setSubmissionError({
          message: t(
            "components.refugeeDistributionModal.errorMessage.unknown",
          ),
        });
      });

    amplitude.track("Refugee Flow Button Clicked", {
      button_description: "Apply Federal State selection",
      step_name: currentStep,
    });

    setIsLoadingData(false);
  };

  const handleChange = (value) => {
    setSelectedFederalState(value);
    setSubmissionError(null);
  };

  const dropdownProps = {
    dropdownOptions: misc.statesOfGermany.STATES_OF_GERMANY_OPTIONS,
    handleChange,
    selectedValue: selectedFederalState,
    inputPlaceholder: t("select.placeholder"),
    valueParamName: "value",
    textParamName: "text",
  };

  return (
    <Step
      stepTitle={t(
        "components.refugeeDistributionModal.pleaseSelectAFederalState",
      )}
      stepIcon={<MarkerIcon />}
      dropdownProps={dropdownProps}
      error={submissionError}
      primaryButtonLabel={t("apply")}
      primaryButtonDisabled={!selectedFederalState}
      primaryButtonIsLoading={isLoadingData}
      onClickPrimaryButton={apply}
      secondaryButtonLabel={t(
        "components.refugeeDistributionModal.noAssignedFederalState",
      )}
      onClickSecondaryButton={() => {
        amplitude.track("Refugee Flow Button Clicked", {
          button_description: "No Federal State assigned",
          step_name: currentStep,
        });

        applyRefugeeTeritorialSearch({
          federalState: null,
          municipality: null,
        });
      }}
      buttonsWidthVariant="growWithText"
      dataTestId="FederalStateSelectionStep"
      primaryButtonDataTestId="FederalStateSelectionStep-applyButton"
      secondaryButtonDataTestId="FederalStateSelectionStep-noFederalStateButton"
    />
  );
};

FederalStateSelection.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.shape({
    federalState: PropTypes.string,
    municipality: PropTypes.string,
  }),
  currentStep: PropTypes.string.isRequired,
  applyRefugeeTeritorialSearch: PropTypes.func,
};

export default FederalStateSelection;
