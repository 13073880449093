import React from "react";
import PropTypes from "prop-types";
import { listings } from "@wunderflats/constants";
import StyledCheckbox from "../../../../../components/StyledCheckbox/StyledCheckbox";

const propTypes = {
  t: PropTypes.func.isRequired,
  type: PropTypes.object,
  setAccommodationType: PropTypes.func.isRequired,
};
const { HOME_TYPES } = listings;

const AccommodationTypeFilter = ({ t, type, setAccommodationType }) => {
  const filterTypes = [
    {
      label: t(
        "spages.listing.ListingDetailsPageBase.HomeType.entireApartment",
      ),
      type: HOME_TYPES.EntireApartment,
      testid: "entireApartment",
    },
    {
      label: t("spages.listing.ListingDetailsPageBase.HomeType.entireHouse"),
      type: HOME_TYPES.EntireHouse,
      testid: "entireHouse",
    },
    {
      label: t("spages.listing.ListingDetailsPageBase.HomeType.privateRoom"),
      type: HOME_TYPES.PrivateRoom,
      testid: "privateRoom",
    },
    {
      label: t("spages.listing.ListingDetailsPageBase.HomeType.sharedPlace"),
      type: HOME_TYPES.SharedPlace,
      testid: "sharedPlace",
    },
  ];

  const handleChange = (value) => () =>
    type === value ? setAccommodationType("") : setAccommodationType(value);

  return (
    <div className="AccommodationTypeFilter">
      <h3 className="AccommodationTypeFilter-heading">{t("homeType")}</h3>
      <input name="homeType" type="hidden" value={type} />
      {filterTypes.map((filterType) => (
        <div
          className="AccommodationTypeFilter-checkboxOuter"
          key={filterType.label}
        >
          <StyledCheckbox
            label={filterType.label}
            shape="circle"
            checked={type === filterType.type}
            onChange={handleChange(filterType.type)}
            data-testid={`AccommodationTypeFilter-${filterType.testid}`}
          />
        </div>
      ))}
    </div>
  );
};

AccommodationTypeFilter.propTypes = propTypes;

export default AccommodationTypeFilter;
