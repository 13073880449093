import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import dayjs from "dayjs";
import intervalPoint from "../../../../../components/DateMonthView/interval-point";
import DateWidget from "../../../../../components/DateWidget/DateWidget";
import { Button } from "../../../../../components/DesignSystem/Button/Button";
import Popover, { placements } from "../../../../../components/Popover/Popover";
import {
  isFromDateAvailable,
  isToDateAvailable,
} from "../../../../../utils/fromToDatesValidation";

const getDateText = ({ t, from, to }) => {
  const dayjsTo = to && dayjs.utc(to);
  const dayjsFrom = from && dayjs.utc(from);

  if (dayjsFrom && dayjsTo) {
    const isSameYear = dayjsFrom.year() === dayjsTo.year();

    return `${
      isSameYear ? dayjsFrom.format("MMM DD") : dayjsFrom.format("MMM DD, YYYY")
    } - ${dayjsTo.format("MMM DD, YYYY")}`;
  }

  if (dayjsFrom) {
    return `${t("from")} ${dayjsFrom.format("MMM DD, YYYY")}`;
  }

  return t("dates");
};

const propTypes = {
  t: PropTypes.func.isRequired,
  to: PropTypes.string,
  from: PropTypes.string.isRequired,
  onFilterSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setDateFilter: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  onHoverDayTo: PropTypes.func.isRequired,
  minDateTo: PropTypes.string.isRequired,
  toHovered: PropTypes.string,
  hasFilters: PropTypes.bool,
  queryParams: PropTypes.object,
};

const DateFilter = ({
  t,
  to,
  from,
  onFilterSelect,
  onSubmit,
  setDateFilter,
  opened,
  minDateTo,
  onHoverDayTo,
  toHovered,
  queryParams,
}) => (
  <Popover
    placement={placements.bottom}
    show={opened}
    onClose={() => onFilterSelect(null)}
    usePortal={false}
    around={
      <Button
        type="button"
        size="medium"
        colorVariant={from ? "brandPurple" : "white"}
        className={classnames("ListingFilterButton", {
          "ListingFilterButton--focus": opened,
        })}
        dataTestId="ListingFilter-datesButton"
        onClick={() => {
          setDateFilter("to", queryParams.to || "");
          setDateFilter("from", queryParams.from || "");
          onFilterSelect("date");
        }}
      >
        {getDateText({ t, from: queryParams.from, to: queryParams.to })}
      </Button>
    }
    innerClassname="ListingFilter-popperInner"
  >
    <div className="ListingFilter-dates">
      <input name="from" type="hidden" value={from} />
      <input name="to" type="hidden" value={to} />
      <div
        className="ListingFilter-field-calendar"
        data-testid="ListingFilter-from-date"
      >
        <span>{t("from")}</span>
        <DateWidget
          t={t}
          name="ListingFilter-from-date"
          selected={from}
          isDateAvailable={(date) => isFromDateAvailable({ date })}
          onSelect={(val) => setDateFilter("from", val)}
          setAttributes={intervalPoint(toHovered || to)}
          clearButtonText={t("today")}
          minDate={dayjs.utc().format("YYYY-MM-DD")}
        />
      </div>
      <div
        className="ListingFilter-field-calendar"
        data-testid="ListingFilter-to-date"
      >
        <span>{t("to")}</span>
        <DateWidget
          t={t}
          name="ListingFilter-to-date"
          selected={to}
          isDateAvailable={(date) =>
            isToDateAvailable({
              from,
              date,
            })
          }
          onSelect={(val) => setDateFilter("to", val)}
          setAttributes={intervalPoint(from)}
          onHoverDay={onHoverDayTo}
          minDate={dayjs.utc(minDateTo).toISOString()}
        />
      </div>
    </div>
    <Button
      colorVariant="primary"
      widthVariant="fullWidth"
      dataTestId="ListingFilter-datesSubmit"
      onClick={onSubmit}
    >
      {t("apply")}
    </Button>
  </Popover>
);

DateFilter.propTypes = propTypes;

export default DateFilter;
