import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./Badge.scss";

export const badgeVariants = {
  brand: "brand",
  primary: "primary",
  grey: "grey",
  silver: "silver",
  whiteTransparent: "whiteTransparent",
  silverOutline: "silverOutline",
  successOutline: "successOutline",
  brandOutline: "brandOutline",
  warningOutline: "warningOutline",
};

const propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(badgeVariants)),
  maxChars: PropTypes.number,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
};

const Badge = ({
  text,
  className,
  variant,
  dataTestId,
  maxChars,
  leftIcon,
  rightIcon,
}) => (
  <span
    data-testid={dataTestId}
    className={classnames("Badge", className, {
      "Badge--brand": variant === badgeVariants.brand,
      "Badge--primary": variant === badgeVariants.primary,
      "Badge--grey": variant === badgeVariants.grey,
      "Badge--silver": variant === badgeVariants.silver,
      "Badge--white-transparent": variant === badgeVariants.whiteTransparent,
      "Badge--silverOutline": variant === badgeVariants.silverOutline,
      "Badge--successOutline": variant === badgeVariants.successOutline,
      "Badge--brandOutline": variant === badgeVariants.brandOutline,
      "Badge--warningOutline": variant === badgeVariants.warningOutline,
    })}
  >
    {leftIcon && <React.Fragment>{leftIcon} </React.Fragment>}
    {text?.length > maxChars ? `${text.slice(0, maxChars)}…` : text}
    {rightIcon && <React.Fragment> {rightIcon}</React.Fragment>}
  </span>
);

Badge.propTypes = propTypes;
export default Badge;
