import React from "react";
import PropTypes from "prop-types";

const GalleryNavigation = ({ images, selectNext, selectPrevious }) => {
  if (!images || images?.length <= 1) {
    return null;
  }

  return (
    <div className="ImageGallery-navButtonsOuter">
      <button
        onClick={selectPrevious}
        className="ImageGallery-navButton ImageGallery-navButton--left"
      >
        <img src="/public/images/gallery-nav-prev-icon.svg" alt="Previous" />
      </button>
      <button
        onClick={selectNext}
        className="ImageGallery-navButton ImageGallery-navButton--right"
      >
        <img src="/public/images/gallery-nav-next-icon.svg" alt="Next" />
      </button>
    </div>
  );
};

GalleryNavigation.propTypes = {
  images: PropTypes.array,
  selectNext: PropTypes.func,
  selectPrevious: PropTypes.func,
};

export default GalleryNavigation;
