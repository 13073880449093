import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  purple: PropTypes.bool,
};

const SingleBed = ({ purple }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
    <rect fill="#e2e2e2" opacity="0" width="22" height="22" />
    <path
      fill={purple ? "#eee6fb" : "#edf1f4"}
      d="M15.42,8.33H6.58A.53.53,0,0,1,6,7.86V4.42A.93.93,0,0,1,7,3.6h8a.92.92,0,0,1,1,.82V7.86A.52.52,0,0,1,15.42,8.33Z"
    />
    <path
      fill={purple ? "#eee6fb" : "#fff"}
      d="M16.72,12.84H5.29c-.48,0-1.12-.24-1-.64l1.39-4a.73.73,0,0,1,.72-.49h9.22a.73.73,0,0,1,.72.49l1.37,4C17.83,12.59,17.21,12.84,16.72,12.84Z"
    />
    <path
      fill={purple ? "#d9c3fb" : "#e2e2e2"}
      d="M13.35,9.21H8.65a.49.49,0,0,1-.49-.49V6.29a.49.49,0,0,1,.49-.49h4.7a.49.49,0,0,1,.49.49V8.72A.48.48,0,0,1,13.35,9.21Z"
    />
    <rect
      fill={purple ? "#d9c3fb" : "#e2e2e2"}
      x="4"
      y="11.89"
      width="14"
      height="5"
      rx="1"
    />
    <path d="M18.28,12,16.82,8.05a1.09,1.09,0,0,0-.32-.51V4.42A1.41,1.41,0,0,0,15,3.11H7A1.42,1.42,0,0,0,5.5,4.42V7.54A1.09,1.09,0,0,0,5.21,8L3.75,11.94a2.21,2.21,0,0,0-.25,1v3A1.5,1.5,0,0,0,5,17.4H6.19v1.49h1V17.4h7.63v1.49h1V17.4H17a1.5,1.5,0,0,0,1.5-1.5v-3A2.19,2.19,0,0,0,18.28,12ZM7,4.11h8c.28,0,.49.16.49.31l0,2.78H14.09V6.3a.74.74,0,0,0-.74-.74H8.65a.74.74,0,0,0-.74.74v.9H6.5V4.42C6.5,4.27,6.71,4.11,7,4.11ZM13.59,8.2v.53a.2.2,0,0,1,0,.11.23.23,0,0,1-.21.12H8.65a.24.24,0,0,1-.21-.12.2.2,0,0,1,0-.11V6.3a.24.24,0,0,1,.24-.24h4.7a.24.24,0,0,1,.24.24V8.2ZM6,8.7l.13-.38A.24.24,0,0,1,6.4,8.2H7.91v.53a.41.41,0,0,0,0,.11.73.73,0,0,0,.73.62h4.7a.73.73,0,0,0,.73-.62.41.41,0,0,0,0-.11V8.2h1.53a.25.25,0,0,1,.25.15l.12.34.93,2.71H5.1ZM17.5,15.9a.5.5,0,0,1-.5.5H5a.51.51,0,0,1-.5-.5v-3a.5.5,0,0,1,.5-.5H17a.5.5,0,0,1,.5.5v3Z" />
  </svg>
);

SingleBed.propTypes = propTypes;

export default SingleBed;
