import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ComponentLazyLoad from "../ComponentLazyLoad";
import CoverImage from "../CoverImage/CoverImage";
import Img from "../Img/Img";
import SvgSpinner from "../SvgSpinner/SvgSpinner";
import { setImageRefLoading } from "./utils";

export const MobileImage = ({
  index,
  src,
  srcSet,
  imagesRefs,
  sectionLabel,
  touch,
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <figure
      id={index}
      ref={imagesRefs[index]}
      className={classNames("ImageGallery-image", {
        "ImageGallery-image--touch": touch,
      })}
    >
      {loading && (
        <div className="ImageGallery-loader">
          <SvgSpinner />
        </div>
      )}
      <ComponentLazyLoad wrapperClassName="ImageGallery-lazyLoad">
        <Img
          ref={(img) => setImageRefLoading(img, setLoading)}
          src={src}
          srcSet={srcSet}
          sizes="(min-width: 370px) 100vw"
        />
        {sectionLabel && !loading && (
          <figcaption className="ImageGallery-caption">
            {sectionLabel}
          </figcaption>
        )}
      </ComponentLazyLoad>
    </figure>
  );
};
MobileImage.propTypes = {
  sectionLabel: PropTypes.string,
  touch: PropTypes.bool,
  imagesRefs: PropTypes.array,
  index: PropTypes.number,
  src: PropTypes.string,
  srcSet: PropTypes.string,
};

export const MobileCoverImage = ({
  index,
  src,
  srcSet,
  imagesRefs,
  touch,
  imageLoadingStrategy,
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <figure
      id={index}
      className={classNames("ImageGallery-image", {
        "ImageGallery-image--touch": touch,
      })}
      ref={imagesRefs[index]}
    >
      {loading && (
        <div className="ImageGallery-loader">
          <SvgSpinner />
        </div>
      )}
      <ComponentLazyLoad wrapperClassName="ImageGallery-lazyLoad">
        <CoverImage
          imgRef={(img) => setImageRefLoading(img, setLoading)}
          src={src}
          srcSet={srcSet}
          loading={imageLoadingStrategy}
        />
      </ComponentLazyLoad>
    </figure>
  );
};
MobileCoverImage.propTypes = {
  index: PropTypes.number,
  src: PropTypes.string,
  srcSet: PropTypes.string,
  touch: PropTypes.bool,
  imagesRefs: PropTypes.array,
  imageLoadingStrategy: PropTypes.oneOf(["lazy", "eager"]),
};
