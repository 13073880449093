import React from "react";
import PropTypes from "prop-types";

import "./BubbleCount.scss";

const BubbleCount = ({ count }) => {
  if (!count) {
    return null;
  }

  return <span className="BubbleCount">{count > 9 ? "9+" : count}</span>;
};

BubbleCount.propTypes = {
  count: PropTypes.number,
};

export default BubbleCount;
