import React from "react";

const PriceFilterLoading = () => {
  return (
    <div className="PriceFilterLoading">
      <div className="PriceFilterLoading-item PriceFilterLoading-histogram" />

      <div className="PriceFilterLoading-labels">
        <div className="PriceFilterLoading-item PriceFilterLoading-labelItem" />
        <div className="PriceFilterLoading-item PriceFilterLoading-labelItem" />
      </div>

      <div className="PriceFilterLoading-inputs">
        <div className="PriceFilterLoading-item PriceFilterLoading-inputItem" />
        <div className="PriceFilterLoading-item PriceFilterLoading-inputItem" />
      </div>

      <div className="PriceFilterLoading-footer">
        <div className="PriceFilterLoading-item PriceFilterLoading-resetLink" />
        <div className="PriceFilterLoading-item PriceFilterLoading-applyButton" />
      </div>
    </div>
  );
};

export default PriceFilterLoading;
