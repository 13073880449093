import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import BubbleCount from "../../../../../components/BubbleCount/BubbleCount";
import { Button } from "../../../../../components/DesignSystem/Button/Button";
import NumberCounter from "../../../../../components/NumberCounter/NumberCounter";
import Popover, { placements } from "../../../../../components/Popover/Popover";

const propTypes = {
  t: PropTypes.func.isRequired,
  minAccommodates: PropTypes.number.isRequired,
  onFilterSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setMinAccommodates: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  hasFilters: PropTypes.bool,
};

const TenantFilter = ({
  t,
  minAccommodates,
  opened,
  onFilterSelect,
  onSubmit,
  setMinAccommodates,
  hasFilters,
}) => {
  const [tenants, setTenants] = React.useState(minAccommodates);

  return (
    <Popover
      placement={placements.bottom}
      show={opened}
      onClose={() => onFilterSelect(null)}
      usePortal={false}
      around={
        <Button
          type="button"
          size="medium"
          colorVariant={hasFilters ? "brandPurple" : "white"}
          className={classnames("ListingFilterButton", {
            "ListingFilterButton--focus": opened,
          })}
          dataTestId="ListingFilter-tenantsButton"
          onClick={() => {
            setTenants(minAccommodates);
            onFilterSelect("tenant");
          }}
        >
          <span className="ListingFilterButton-text">{t("tenants")}</span>
          {hasFilters && <BubbleCount count={minAccommodates} />}
        </Button>
      }
      innerClassname="ListingFilter-popperInner"
    >
      <div className="ListingFilter-tenants">
        <NumberCounter
          value={tenants}
          label={t("pages.ListingsPage.filters.tenants")}
          disableDecrement={tenants <= 1}
          onDecrement={() => setTenants(tenants - 1)}
          onIncrement={() => setTenants(tenants + 1)}
          inputName="minAccommodates"
        />
        <Button
          colorVariant="primary"
          type="button"
          widthVariant="fullWidth"
          className="ListingFilter-tenantsSubmit"
          dataTestId="ListingFilter-tenantsSubmit"
          onClick={() => setMinAccommodates(tenants, onSubmit)}
        >
          {t("apply")}
        </Button>
      </div>
    </Popover>
  );
};

TenantFilter.propTypes = propTypes;

export default TenantFilter;
