import React, { createRef } from "react";
import PropTypes from "prop-types";
import { createRoot } from "react-dom/client";
import scrollPosition from "../../../utils/scroll-position";

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  around: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  preferPosition: PropTypes.object,
  forcePreferredYPosition: PropTypes.bool,
  onPopoverRender: PropTypes.func,
};

class Popover extends React.Component {
  constructor(props) {
    super(props);
    this.onResize = this.onResize.bind(this);
    this.anchorRef = createRef();
  }

  componentDidMount() {
    this.popover = document.createElement("div");
    this.popover.className = "_Popover";
    this.popoverRoot = createRoot(this.popover);

    this._detachedContainer = document.createElement("div");
    this._detachedContainerRoot = createRoot(this._detachedContainer);
    this._detachedContainer.style.display = "inline-block";
    this._detachedContainer.style.visibility = "hidden";
    this._detachedContainer.style.position = "absolute";
    this._detachedContainer.style.top = 0;
    this._detachedContainer.style.left = 0;
    this._detachedContainer.className = this.props.className;

    document.body.appendChild(this.popover);
    document.body.appendChild(this._detachedContainer);

    this._renderPopover();

    if (this.props.show) {
      window.addEventListener("resize", this.onResize);
    }
  }

  // TODO: https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillReceiveProps(props) {
    // if we are now showing the popover
    if (!this.props.show && props.show) {
      window.addEventListener("resize", this.onResize);
    }

    // if we are now hiding the popover
    if (this.props.show && !props.show) {
      window.removeEventListener("resize", this.onResize);
    }
  }

  componentDidUpdate() {
    this._renderPopover();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
    if (this.popover.parentNode !== document.body) return;

    setTimeout(() => {
      if (this.popoverRoot) {
        this.popoverRoot.unmount();
        if (document.body.contains(this.popover)) {
          document.body.removeChild(this.popover);
        }
      }

      if (this._detachedContainerRoot) {
        this._detachedContainerRoot.unmount();
        if (document.body.contains(this._detachedContainer)) {
          document.body.removeChild(this._detachedContainer);
        }
      }
    });
  }

  onResize() {
    this.forceUpdate();
  }

  getViewportBounds() {
    const { x, y } = scrollPosition();
    return {
      left: x,
      right: x + window.innerWidth,
      top: y,
      bottom: y + window.innerHeight,
    };
  }

  getAnchorBounds() {
    const bounds = this.anchorRef.current?.getBoundingClientRect();
    const viewport = this.getViewportBounds();

    const a = {
      left: bounds.left + viewport.left,
      right: bounds.right + viewport.left,
      top: bounds.top + viewport.top,
      bottom: bounds.bottom + viewport.top,
    };

    return a;
  }

  getBodySize() {
    return {
      width: this._detachedContainer.offsetWidth,
      height: this._detachedContainer.offsetHeight,
    };
  }

  calculatePosition() {
    const docbounds = this.getViewportBounds();
    const rect = this.getAnchorBounds();
    const body = this.getBodySize();

    const position = this.props.preferPosition || { x: "left", y: "bottom" };

    if (
      !this.props.forcePreferredYPosition &&
      rect.bottom + body.height >= docbounds.bottom
    ) {
      position.y = "top";
    }

    if (rect.right + body.width >= docbounds.right) {
      position.x = "right";
    }

    const values = this.calculatePositionValues(position);

    if (values.left < 0) {
      position.x = "left";
    }

    return position;
  }

  calculatePositionValues(position) {
    const docbounds = this.getViewportBounds();
    const bounds = this.getAnchorBounds();
    const body = this.getBodySize();

    const values = {};

    if (position.y === "bottom") {
      values.top = bounds.bottom;
    } else {
      values.top = bounds.top - body.height;
    }

    if (position.x === "left") {
      values.left = bounds.left;
    } else if (position.x === "center") {
      const anchorWidth = bounds.right - bounds.left;
      const anchorCenter = bounds.left + anchorWidth / 2;
      values.left = anchorCenter - body.width / 2;
    } else {
      values.left = bounds.right - body.width;
    }

    if (values.left + body.width > docbounds.right) {
      values.left = docbounds.right - body.width;
    }
    return values;
  }

  calculateStyle(position, values) {
    const style = { position: "absolute", zIndex: "10000" };
    return Object.assign(
      style,
      values || this.calculatePositionValues(position),
    );
  }

  _renderPopover() {
    if (!this.props.show) {
      this.popoverRoot?.render(
        <div style={{ display: "none" }} className="Popover" />,
      );
      return;
    }
    this._detachedContainerRoot.render(this.props.children);

    const position = this.calculatePosition();
    const style = this.calculateStyle(position);

    const className = `Popover Popover--position-${position.y}-${position.x}${this.props.className ? ` ${this.props.className}` : ""}`;

    const renderedPopover = this.popoverRoot.render(
      <div style={style} className={className}>
        {this.props.children}
      </div>,
    );

    if (renderedPopover && this.props.onPopoverRender) {
      this.props.onPopoverRender(renderedPopover);
    }
  }

  render() {
    return <div ref={this.anchorRef}>{this.props.around}</div>;
  }
}

Popover.propTypes = propTypes;
export default Popover;
