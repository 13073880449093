import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  purple: PropTypes.bool,
};

const DoubleBed = ({ purple }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
    <rect fill="#e2e2e2" opacity="0" width="22" height="22" />
    <path
      fill={purple ? "#eee6fb" : "#edf1f4"}
      d="M18,8.41H4a.49.49,0,0,1-.49-.49V4.54a1,1,0,0,1,1-1h13a1,1,0,0,1,1,1V7.92A.48.48,0,0,1,18,8.41Z"
    />
    <path
      fill={purple ? "#eee6fb" : "#fff"}
      d="M19.47,12.94H2.53c-.71,0-1.24-.4-1.06-.81L3.12,8.24a1.13,1.13,0,0,1,1.06-.5H17.82a1.15,1.15,0,0,1,1.07.5l1.64,3.89C20.71,12.54,20.18,12.94,19.47,12.94Z"
    />
    <path
      fill={purple ? "#d9c3fb" : "#edf1f4"}
      d="M19.63,16.82H2.37a1,1,0,0,1-1-1V12.85a1,1,0,0,1,1-1H19.63a1,1,0,0,1,1,1v2.94A1,1,0,0,1,19.63,16.82Z"
    />
    <path
      fill={purple ? "#d9c3fb" : "#edf1f4"}
      d="M10,9.14H5.31a.5.5,0,0,1-.49-.49V6.22a.49.49,0,0,1,.49-.49H10a.49.49,0,0,1,.49.49V8.65A.49.49,0,0,1,10,9.14Z"
    />
    <path
      fill={purple ? "#d9c3fb" : "#edf1f4"}
      d="M16.69,9.14H12a.5.5,0,0,1-.49-.49V6.22A.49.49,0,0,1,12,5.73h4.7a.49.49,0,0,1,.49.49V8.65A.49.49,0,0,1,16.69,9.14Z"
    />
    <path d="M21,11.94,19.35,8A1.08,1.08,0,0,0,19,7.6V4.54A1.5,1.5,0,0,0,17.49,3h-13A1.5,1.5,0,0,0,3,4.54V7.6A1.06,1.06,0,0,0,2.66,8L1,11.94a2.66,2.66,0,0,0-.14.91v2.94a1.52,1.52,0,0,0,1.5,1.53H3.89V19h1V17.32H17.52V19h1V17.32h1.11a1.52,1.52,0,0,0,1.5-1.53V12.85A2.86,2.86,0,0,0,21,11.94ZM4,4.54a.5.5,0,0,1,.5-.5h13a.5.5,0,0,1,.5.5V7.25h-.57v-1a.74.74,0,0,0-.74-.74H12a.74.74,0,0,0-.74.74v1h-.5v-1A.74.74,0,0,0,10,5.48H5.31a.74.74,0,0,0-.74.74v1H4Zm12.92,3.7v.41a.23.23,0,0,1-.24.23H12a.23.23,0,0,1-.24-.23V6.22A.24.24,0,0,1,12,6h4.7a.24.24,0,0,1,.24.24v2Zm-6.68,0v.41a.23.23,0,0,1-.24.23H5.31a.23.23,0,0,1-.24-.23V6.22A.24.24,0,0,1,5.31,6H10a.24.24,0,0,1,.24.24v2Zm-6.8.5.13-.31a.82.82,0,0,1,.6-.19h.39v.41a.74.74,0,0,0,.74.73H10a.72.72,0,0,0,.69-.47.75.75,0,0,0,.05-.26V8.24h.5v.41a.74.74,0,0,0,.74.73h4.7a.74.74,0,0,0,.74-.73V8.24h.39a.85.85,0,0,1,.61.19l.13.31,1.12,2.59H2.36Zm16.68,7a.51.51,0,0,1-.5.53H2.37a.51.51,0,0,1-.5-.53V12.85a.52.52,0,0,1,.26-.47h0a.46.46,0,0,1,.23-.06H19.63a.43.43,0,0,1,.22.06.49.49,0,0,1,.28.47v2.94Z" />
  </svg>
);

DoubleBed.propTypes = propTypes;

export default DoubleBed;
