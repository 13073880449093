import React from "react";
import PropTypes from "prop-types";

const HouseInCircleIcon = ({ width = 60, height = 60, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="30"
        cy="30"
        r="29.5"
        fill="#9AB4DE"
        fillOpacity=".2"
        stroke="#9AB4DE"
      />
      <path
        d="M41.65 41.69H29.81V21.95L35.73 18l5.92 3.95v19.74Z"
        fill="#fff"
        stroke="#170C37"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M34.81 25.39h-2.37v4.74h2.37v-4.74Z"
        stroke="#170C37"
        strokeWidth=".75"
        strokeMiterlimit="10"
      />
      <path
        d="M32.44 28.39h2.37"
        stroke="#170C37"
        strokeWidth=".5"
        strokeMiterlimit="10"
      />
      <path
        d="M39.02 25.39h-2.37v4.74h2.37v-4.74Z"
        stroke="#170C37"
        strokeWidth=".75"
        strokeMiterlimit="10"
      />
      <path
        d="M36.65 28.39h2.37"
        stroke="#170C37"
        strokeWidth=".5"
        strokeMiterlimit="10"
      />
      <path
        d="M34.81 32.2h-2.37v4.74h2.37V32.2Z"
        stroke="#170C37"
        strokeWidth=".75"
        strokeMiterlimit="10"
      />
      <path
        d="M32.44 35.2h2.37"
        stroke="#170C37"
        strokeWidth=".5"
        strokeMiterlimit="10"
      />
      <path
        d="M39.02 32.2h-2.37v4.74h2.37V32.2Z"
        stroke="#170C37"
        strokeWidth=".75"
        strokeMiterlimit="10"
      />
      <path
        d="M36.65 35.2h2.37"
        stroke="#170C37"
        strokeWidth=".5"
        strokeMiterlimit="10"
      />
      <path
        d="M29.85 41.69H18V21.95L23.93 18l5.92 3.95v19.74Z"
        fill="#fff"
        stroke="#170C37"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M23.01 25.39h-2.37v4.74h2.37v-4.74Z"
        stroke="#170C37"
        strokeWidth=".75"
        strokeMiterlimit="10"
      />
      <path
        d="M20.64 28.39h2.37"
        stroke="#170C37"
        strokeWidth=".5"
        strokeMiterlimit="10"
      />
      <path
        d="M27.22 25.39h-2.37v4.74h2.37v-4.74Z"
        stroke="#170C37"
        strokeWidth=".75"
        strokeMiterlimit="10"
      />
      <path
        d="M24.85 28.39h2.37"
        stroke="#170C37"
        strokeWidth=".5"
        strokeMiterlimit="10"
      />
      <path
        d="M23.01 32.2h-2.37v4.74h2.37V32.2Z"
        stroke="#170C37"
        strokeWidth=".75"
        strokeMiterlimit="10"
      />
      <path
        d="M20.64 35.2h2.37"
        stroke="#170C37"
        strokeWidth=".5"
        strokeMiterlimit="10"
      />
      <path
        d="M27.22 32.2h-2.37v4.74h2.37V32.2Z"
        stroke="#170C37"
        strokeWidth=".75"
        strokeMiterlimit="10"
      />
      <path
        d="M24.85 35.2h2.37"
        stroke="#170C37"
        strokeWidth=".5"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

HouseInCircleIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default HouseInCircleIcon;
