import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";

const HoveredItemContext = React.createContext({
  hoveredItem: "",
  setHoveredItem: () => {},
});

const providerPropTypes = {
  initialHoveredItem: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export function HoveredItemContextProvider({ initialHoveredItem, children }) {
  const [hoveredItem, setHoveredItem] = useState(initialHoveredItem);
  const contextValue = useMemo(
    () => ({ hoveredItem, setHoveredItem }),
    [hoveredItem, setHoveredItem],
  );

  return (
    <HoveredItemContext.Provider value={contextValue}>
      {children}
    </HoveredItemContext.Provider>
  );
}

HoveredItemContextProvider.propTypes = providerPropTypes;

export function useHoveredItem() {
  return useContext(HoveredItemContext);
}
