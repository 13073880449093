import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import ArrowRight from "../ArrowRight/ArrowRight";
import LeftArrow from "../LeftArrow/LeftArrow";
import EllipsisItem from "./EllipsisItem";
import PaginationItem from "./PaginationItem";
import "./Pagination.scss";

const getPaginationItems = ({ pageCount, currentPage, getUrl }) => {
  const buttons = [];
  const renderPaginationButton = (buttonPage) => (
    <PaginationItem
      key={buttonPage}
      url={getUrl(buttonPage)}
      pageNumber={buttonPage}
      currentPage={currentPage}
    />
  );

  // if there are <= 5 pages, show buttons for each page
  if (pageCount <= 5) {
    for (let i = 1; i <= pageCount; i++) {
      buttons.push(renderPaginationButton(i));
    }
  } else {
    // always show first page
    buttons.push(renderPaginationButton(1));

    if (currentPage <= 3) {
      // pagination format: 1 2 3 4 5 ... 19
      buttons.push(renderPaginationButton(2));
      buttons.push(renderPaginationButton(3));
      buttons.push(renderPaginationButton(4));
      buttons.push(renderPaginationButton(5));
      buttons.push(<EllipsisItem key="right-ellipsis" />);
    } else if (currentPage > 3 && currentPage < pageCount - 2) {
      // pagination format: 1 ... 7 8 9 10 11 ... 19
      if (currentPage - 3 !== 1)
        buttons.push(<EllipsisItem key="left-ellipsis" />);
      buttons.push(renderPaginationButton(currentPage - 2));
      buttons.push(renderPaginationButton(currentPage - 1));
      buttons.push(renderPaginationButton(currentPage));
      buttons.push(renderPaginationButton(currentPage + 1));
      buttons.push(renderPaginationButton(currentPage + 2));
      if (currentPage + 3 !== pageCount)
        buttons.push(<EllipsisItem key="right-ellipsis" />);
    } else {
      // pagination format: 1 ... 15 16 17 18 19
      buttons.push(<EllipsisItem key="left-ellipsis" />);
      buttons.push(renderPaginationButton(pageCount - 4));
      buttons.push(renderPaginationButton(pageCount - 3));
      buttons.push(renderPaginationButton(pageCount - 2));
      buttons.push(renderPaginationButton(pageCount - 1));
    }

    // always show last page
    buttons.push(renderPaginationButton(pageCount));
  }

  return <React.Fragment>{buttons.map((button) => button)}</React.Fragment>;
};

const getPaginationItemsMobile = ({ pageCount, currentPage, getUrl }) => {
  const buttons = [];
  const renderPaginationButton = (buttonPage) => (
    <PaginationItem
      key={buttonPage}
      url={getUrl(buttonPage)}
      pageNumber={buttonPage}
      currentPage={currentPage}
    />
  );

  // if there are <= 5 pages, show buttons for each page
  if (pageCount <= 3) {
    for (let i = 1; i <= pageCount; i++) {
      buttons.push(renderPaginationButton(i));
    }
  } else {
    buttons.push(renderPaginationButton(1));
    if (currentPage <= 2) {
      // pagination format: 1 2 ... 19
      buttons.push(renderPaginationButton(2));
      buttons.push(<EllipsisItem key="right-ellipsis" />);
    } else if (currentPage >= pageCount - 1) {
      // pagination format: 1 ... 18 19
      buttons.push(<EllipsisItem key="left-ellipsis" />);
      buttons.push(renderPaginationButton(pageCount - 1));
    } else {
      // pagination format: 1 ... 10 ... 19
      buttons.push(<EllipsisItem key="left-ellipsis" />);
      buttons.push(renderPaginationButton(currentPage));
      buttons.push(<EllipsisItem key="right-ellipsis" />);
    }
    buttons.push(renderPaginationButton(pageCount));
  }

  return <React.Fragment>{buttons.map((button) => button)}</React.Fragment>;
};

const propTypes = {
  currentPage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  getUrl: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

const Pagination = ({ currentPage, total, itemsPerPage, getUrl, isMobile }) => {
  const pageCount = Math.ceil(total / itemsPerPage);
  if (pageCount <= 1) return null;

  const isPrevDisabled = currentPage === 1;
  const isNextDisabled = currentPage === pageCount;
  const prevPage = isPrevDisabled ? 1 : currentPage - 1;
  const nextPage = isNextDisabled ? pageCount : currentPage + 1;

  return (
    <nav className="Pagination" data-testid="Pagination">
      <Link
        className={classNames("PaginationItem Pagination-previous", {
          disabled: isPrevDisabled,
        })}
        to={getUrl(prevPage)}
        data-testid="Pagination-prevButton"
      >
        <LeftArrow
          fillColor={isPrevDisabled ? "#b5bec5" : "#24272e"}
          className="Pagination-arrowLeft"
        />
      </Link>
      {isMobile
        ? getPaginationItemsMobile({ pageCount, currentPage, getUrl })
        : getPaginationItems({ pageCount, currentPage, getUrl })}
      <Link
        className={classNames("PaginationItem Pagination-next", {
          disabled: isNextDisabled,
        })}
        to={getUrl(nextPage)}
        data-testid="Pagination-nextButton"
      >
        <ArrowRight
          pathClassName="Pagination-arrowRight"
          fillColor={isNextDisabled ? "#b5bec5" : "#24272e"}
        />
      </Link>
    </nav>
  );
};

Pagination.propTypes = propTypes;

export default Pagination;
