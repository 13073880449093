import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from "react-router-dom";
import "./Pagination.scss";

const PaginationItem = ({ url, pageNumber, currentPage }) => (
  <Link
    to={url}
    key={`p${pageNumber}`}
    className={classnames("PaginationItem", {
      "PaginationItem--active": currentPage === pageNumber,
    })}
    data-testid={`PaginationItem-${pageNumber}`}
  >
    {pageNumber}
  </Link>
);

PaginationItem.propTypes = {
  url: PropTypes.string.isRequired,
  pageNumber: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default PaginationItem;
