import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./StudentExclusivePill.scss";

const StudentExclusivePill = ({ short = false, t }) => {
  return (
    <div
      className={classnames("StudentExclusivePill", {
        "StudentExclusivePill--short": short,
      })}
    >
      {short ? t("forStudents") : t("exclusivelyForStudents")}
    </div>
  );
};

StudentExclusivePill.propTypes = {
  t: PropTypes.func.isRequired,
  short: PropTypes.bool,
};

export default StudentExclusivePill;
