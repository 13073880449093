import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useI18n } from "../../spages/spa/context/I18nContext";
import BackButton from "../BackButton/BackButton";
import Card from "../Card/Card";
import { actions } from "./constants";
import ProgressBar from "./ProgressBar";

import "./MainLayout.scss";

const ModalLayout = ({ children, dispatch, history, calculatedProgress }) => {
  const stepContainerRef = useRef(null);
  const { t } = useI18n();

  const goToPreviousStep = () => {
    dispatch({ type: actions.GO_TO_PREVIOUS_STEP });
  };

  const scrollToCenter = () => {
    if (stepContainerRef?.current) {
      const containerHeight =
        stepContainerRef?.current.getBoundingClientRect().height / 2;

      window.scrollTo({
        top: containerHeight,
        behavior: "smooth",
      });
    }
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    const props = { scrollToCenter };
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...props, ...child.props });
    }
    return child;
  });

  return (
    <Card
      noPadding
      className="RefugeeDistributionModal-Layout"
      ref={stepContainerRef}
    >
      <div
        className="RefugeeDistributionModal-StepWrapper"
        data-testid="RefugeeDistributionModal"
      >
        {childrenWithProps}
      </div>
      <div className="RefugeeDistributionModal-Footer">
        <ProgressBar calculatedProgress={calculatedProgress} />
        {history.length !== 0 && (
          <BackButton
            className="RefugeeDistributionModal-backButton"
            type="transparent"
            label={t("back")}
            onClick={goToPreviousStep}
            data-testid="RefugeeDistributionModal-BackButton"
          />
        )}
      </div>
    </Card>
  );
};

ModalLayout.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  history: PropTypes.array,
  calculatedProgress: PropTypes.number,
};

export default ModalLayout;
