import React, { useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import trackable from "../../analytics/trackables/trackable";
import { useHoveredItem } from "../../spages/spa/context/HoveredItemContext";
import { useI18n } from "../../spages/spa/context/I18nContext";
import Listing from "../Listing/Listing";
import "./ListingsList.scss";

const ListingsList = ({
  items,
  url,
  city,
  categories,
  onClickListing,
  onSelectImage,
  isSmallScreen,
  query,
  dataLayerKey,
  listingCardTrackingCallback,
  title,
  totalItems,
  showtitle = true,
}) => {
  const { t, lang, extractContent } = useI18n();
  const listingCardTrackingCallbackRef = useRef(listingCardTrackingCallback);

  const trackingListingsCallback = useMemo(() => {
    if (items && items.length) {
      return items.reduce((acc, current, index) => {
        acc[index] = () => {
          listingCardTrackingCallbackRef.current(current);
        };
        return acc;
      }, {});
    }
  }, [items]);

  const { hoveredItem, setHoveredItem } = useHoveredItem();

  const handleMouseEnterListing = (listing) => {
    setHoveredItem(listing.groupId);
  };

  const handleMouseLeaveListing = (listing) => {
    if (setHoveredItem && listing.groupId === hoveredItem) {
      setHoveredItem("");
    }
  };

  useEffect(() => {
    listingCardTrackingCallbackRef.current = listingCardTrackingCallback;
  }, [listingCardTrackingCallback]);

  if (!items.length) {
    return null;
  }

  return (
    <React.Fragment>
      {showtitle && (
        <header className="ListingsList-title">
          <h1 className="ListingsList-title-text">{title}</h1>
          <span>
            {totalItems > 0 && totalItems <= 200
              ? ` - ${totalItems} ${t("pages.ListingsPage.searchResults")}`
              : ` - ${t("pages.ListingsPage.moreThan")} 200 ${t(
                  "pages.ListingsPage.searchResults",
                )}`}
          </span>
        </header>
      )}
      <article className="ListingsList" data-testid="ListingsList">
        {items.map((listing, index) => {
          return trackable(
            <Listing
              t={t}
              lang={lang}
              extractContent={extractContent}
              url={url}
              categories={categories}
              city={city}
              onClick={() => {
                if (onClickListing) {
                  onClickListing(listing, index);
                }
              }}
              query={query}
              dataLayerKey={dataLayerKey}
              onMouseEnter={() => handleMouseEnterListing(listing)}
              onMouseLeave={() => handleMouseLeaveListing(listing)}
              isSmallScreen={isSmallScreen}
              key={listing.groupId}
              highlight={hoveredItem === listing.groupId}
              listing={listing}
              onSelectImage={() => {
                if (onSelectImage) {
                  onSelectImage();
                }
              }}
              // We don't lazy load the cover image of the first listing, since it's always above the fold (for performance reasons, to imrpove LCP)
              // Ideally, we would eager load cover images of all listings that are visible on the screen on page load (potential future improvement)
              coverImageLoadingStrategy={index === 0 ? "eager" : "lazy"}
            />,
            trackingListingsCallback[index],
            1000,
            { className: "ListingsList-item" },
          );
        })}
      </article>
    </React.Fragment>
  );
};

ListingsList.propTypes = {
  title: PropTypes.string,
  city: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.object),
  totalItems: PropTypes.number,
  items: PropTypes.array.isRequired,
  url: PropTypes.func.isRequired,
  onClickListing: PropTypes.func,
  onSelectImage: PropTypes.func,
  isSmallScreen: PropTypes.bool,
  query: PropTypes.object.isRequired,
  dataLayerKey: PropTypes.string,
  listingCardTrackingCallback: PropTypes.func.isRequired,
  showtitle: PropTypes.bool,
};

export default ListingsList;
