import React from "react";
import PropTypes from "prop-types";

const EllipsisIcon = ({ className, fillColor = "#7C8489" }) => {
  return (
    <svg
      width="17"
      height="4"
      viewBox="0 0 17 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="2" cy="2" r="2" fill={fillColor} />
      <circle cx="8.40009" cy="2" r="2" fill={fillColor} />
      <circle cx="14.8" cy="2" r="2" fill={fillColor} />
    </svg>
  );
};

EllipsisIcon.propTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
};

export default EllipsisIcon;
