import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import DoubleBed from "../../../../../components/Icons/DoubleBed";
import SingleBed from "../../../../../components/Icons/SingleBed";
import NumberCounter from "../../../../../components/NumberCounter/NumberCounter";
import { isDefaultFilter } from "../../../../../utils/filterUtils";

const propTypes = {
  t: PropTypes.func.isRequired,
  totalSingleBeds: PropTypes.number.isRequired,
  totalDoubleBeds: PropTypes.number.isRequired,
  setTotalSingleBeds: PropTypes.func.isRequired,
  setTotalDoubleBeds: PropTypes.func.isRequired,
};

const BedsFilter = ({
  t,
  totalSingleBeds,
  totalDoubleBeds,
  setTotalSingleBeds,
  setTotalDoubleBeds,
}) => {
  const hasSingleBedsFilter = !isDefaultFilter({ totalSingleBeds });
  const hasDoubleBedsFilter = !isDefaultFilter({ totalDoubleBeds });

  return (
    <div className="BedsFilter">
      <h3 className="BedsFilter-heading">{t("beds")}</h3>
      <NumberCounter
        className={classnames("BedsFilter-singleBeds", {
          "BedsFilter--set": hasSingleBedsFilter,
        })}
        value={totalSingleBeds}
        label={t("single")}
        disableDecrement={totalSingleBeds === 0}
        disableIncrement={totalSingleBeds === 40}
        onDecrement={() => setTotalSingleBeds(totalSingleBeds - 1)}
        onIncrement={() => setTotalSingleBeds(totalSingleBeds + 1)}
        inputName="totalSingleBeds"
        icon={<SingleBed purple={hasSingleBedsFilter} />}
      />
      <NumberCounter
        className={classnames("BedsFilter-doubleBeds", {
          "BedsFilter--set": hasDoubleBedsFilter,
        })}
        value={totalDoubleBeds}
        label={t("double")}
        disableDecrement={totalDoubleBeds === 0}
        disableIncrement={totalDoubleBeds === 40}
        onDecrement={() => setTotalDoubleBeds(totalDoubleBeds - 1)}
        onIncrement={() => setTotalDoubleBeds(totalDoubleBeds + 1)}
        inputName="totalDoubleBeds"
        icon={<DoubleBed purple={hasDoubleBedsFilter} />}
      />
    </div>
  );
};

BedsFilter.propTypes = propTypes;

export default BedsFilter;
