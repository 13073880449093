import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import useEffectExceptOnMount from "../../hooks/useEffectExceptOnMount";
import { useI18n } from "../../spages/spa/context/I18nContext";
import { canUseDOM } from "../../utils/dom";
import {
  translateSectionLabel,
  willSectionRepeat,
} from "../../utils/listing-image-utils";
import {
  constructBasicSrcset,
  getAllImageUrls,
} from "../../utils/wunderflat-image";
import Images from "./Images";
import Thumbnails from "./Thumbnails";
import { getScrollValue } from "./utils";

import "./ImageGallery.scss";

const propTypes = {
  images: PropTypes.array,
  showThumbnails: PropTypes.bool,
  keyboardNavigation: PropTypes.bool,
  onSelectImage: PropTypes.func,
  cover: PropTypes.bool,
  coverImage: PropTypes.object,
  sizes: PropTypes.string,
  coverImageLoadingStrategy: PropTypes.oneOf(["lazy", "eager"]),
};

const ImageGallery = ({
  cover = true,
  coverImage,
  images = [],
  keyboardNavigation = true,
  showThumbnails = true,
  onSelectImage,
  sizes,
  coverImageLoadingStrategy,
}) => {
  const { t } = useI18n();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [touch, setTouch] = useState(
    canUseDOM() ? document.documentElement.classList.contains("touch") : false,
  );

  const thumbnailsContainerRef = useRef(null);
  const imagesContainerRef = useRef(null);
  const thumbnailsRefs = useMemo(
    () => images.map(() => React.createRef()),
    [images],
  );
  const imagesRefs = useMemo(
    () => images.map(() => React.createRef()),
    [images],
  );
  const selectedImage = images?.[selectedIndex];
  const imageSection = selectedImage?.section || selectedImage?.category;
  const imageSections = images?.map((image) => image.section || image.category);
  const sectionRepeat = willSectionRepeat(imageSection, imageSections);

  const translatableSection = imageSection?.replace(/__\d/gi, "");

  let sectionLabel = translateSectionLabel(translatableSection, t);
  if (imageSection && imageSection.search(/__\d/gi) !== -1) {
    sectionLabel = `${sectionLabel} ${imageSection.replace(/(.)*__/gi, "")}`;
  } else if (sectionRepeat) {
    sectionLabel = `${sectionLabel} 1`;
  }

  const moveToSelectedIndex = useCallback(
    ({ index, imageType }) => {
      let container = imagesContainerRef?.current;
      let image = imagesRefs?.[index]?.current;

      if (imageType === "thumbnail") {
        container = thumbnailsContainerRef?.current;
        image = thumbnailsRefs?.[index]?.current;
      }

      if (!container || !image) {
        return;
      }

      container?.scroll({
        left: getScrollValue(container, image),
        behavior: "smooth",
      });
    },
    [imagesContainerRef, imagesRefs, thumbnailsContainerRef, thumbnailsRefs],
  );

  const selectNext = useCallback(
    async ({ reverse } = {}) => {
      let next = (selectedIndex + 1) % images.length;
      if (reverse) {
        next = (selectedIndex - 1 + images.length) % images.length;
      }
      setSelectedIndex(next);
      moveToSelectedIndex({ index: next });
    },
    [selectedIndex, images, moveToSelectedIndex],
  );

  const onKeyDown = useCallback(
    (e) => {
      if (keyboardNavigation === true) {
        // right
        if (e.keyCode === 39) {
          selectNext();
        }

        // left
        if (e.keyCode === 37) {
          selectNext({ reverse: true });
        }
      }
    },
    [keyboardNavigation, selectNext],
  );

  useEffect(() => {
    setTouch(
      showThumbnails && document.documentElement.classList.contains("touch"),
    );
  }, [showThumbnails]);

  useEffect(() => {
    if (keyboardNavigation) addEventListener("keydown", onKeyDown);

    return () => removeEventListener("keydown", onKeyDown);
  }, [keyboardNavigation, onKeyDown]);

  useEffectExceptOnMount(() => {
    if (onSelectImage) {
      onSelectImage(selectedIndex);
    }
    moveToSelectedIndex({ index: selectedIndex, imageType: "thumbnail" });
  }, [selectedIndex, moveToSelectedIndex]);

  const imageUrls = getAllImageUrls(selectedImage);
  const { src, srcSet } = constructBasicSrcset(imageUrls);
  const coverImageUrls =
    selectedIndex === 0 && coverImage ? getAllImageUrls(coverImage) : imageUrls;
  const { src: coverSrc, srcSet: coverSrcSet } =
    constructBasicSrcset(coverImageUrls);

  return (
    <div className="ImageGallery">
      <Images
        ref={imagesContainerRef}
        src={src}
        srcSet={srcSet}
        coverSrc={coverSrc}
        coverSrcSet={coverSrcSet}
        sizes={sizes}
        touch={touch}
        cover={cover}
        coverImageLoadingStrategy={coverImageLoadingStrategy}
        setSelectedIndex={setSelectedIndex}
        selectedIndex={selectedIndex}
        images={images}
        imagesRefs={imagesRefs}
        sectionLabel={sectionLabel}
        selectNext={selectNext}
        selectPrevious={() => selectNext({ reverse: true })}
      />
      <Thumbnails
        ref={thumbnailsContainerRef}
        images={images}
        onClick={(i) => {
          setSelectedIndex(i);
          moveToSelectedIndex({ index: i });
        }}
        showThumbnails={showThumbnails}
        selectedIndex={selectedIndex}
        thumbnailsRefs={thumbnailsRefs}
      />
    </div>
  );
};

ImageGallery.propTypes = propTypes;

export default ImageGallery;
