import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

Trackable.propTypes = {
  component: PropTypes.element.isRequired,
  onView: PropTypes.func.isRequired,
  timeThreshold: PropTypes.number,
};

function Trackable({ component, onView, timeThreshold = 0, ...rest }) {
  const [node, setNode] = useState(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const observerContainer = useRef(null);
  const intersectionTimer = useRef(null);

  const refCallback = useCallback((node) => {
    setNode(node);
  }, []);

  useEffect(() => {
    if (node && !isIntersecting) {
      if (!("IntersectionObserver" in window)) {
        // we won't be tracking listings if IntersectionObserver doesn't exist
        return;
      }

      observerContainer.current = new IntersectionObserver(
        (entries) => {
          if (intersectionTimer.current) {
            clearTimeout(intersectionTimer.current);
          }
          if (entries[0].isIntersecting) {
            intersectionTimer.current = setTimeout(() => {
              setIsIntersecting(true);
            }, timeThreshold);
          } else {
            setIsIntersecting(false);
          }
        },
        { threshold: 0.8 }, // if 80% of the listing item card is visible it's considered to be viewable
      );

      // start observing
      observerContainer.current.observe(node);

      return () => {
        if (observerContainer.current) {
          observerContainer.current.unobserve(node);
        }
      };
    }
  }, [node, isIntersecting, timeThreshold]);

  const onViewRef = useRef(onView);

  useEffect(() => {
    onViewRef.current = onView;
  }, [onView]);

  useEffect(() => {
    if (isIntersecting) {
      if (observerContainer.current) {
        observerContainer.current.unobserve(node);
      }
      onViewRef.current();
    }
  }, [node, isIntersecting]);

  return (
    <div {...rest} ref={refCallback}>
      {component}
    </div>
  );
}

// The timeThreshold should be in ms and is the minimum time the item should be in the viewport for it to be considered viewed
const trackable = (component, callback, timeThreshold, rest) => (
  <Trackable
    component={component}
    onView={callback}
    timeThreshold={timeThreshold}
    key={component.key}
    {...rest}
  />
);

export default trackable;
