import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { prepareModifiersForClassNames } from "../../utils/bem";

const propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.array,
  clickableArea: PropTypes.arrayOf(PropTypes.number),
  isFloating: PropTypes.bool,
  isTranslucid: PropTypes.bool,
  isPrimary: PropTypes.bool,
  isBrand: PropTypes.bool,
  isWhite: PropTypes.bool,
};

const getPixelValue = (value) => {
  if (isNaN(value) || value === 0) return "0";
  return `-${Math.abs(value)}px`;
};

const ExtraAreaButton = ({
  className,
  href,
  children,
  clickableArea = [],
  isFloating,
  isTranslucid,
  isPrimary,
  isBrand,
  isWhite,
  ...props
}) => {
  const El = href ? "a" : "button";
  const buttonRef = React.createRef();
  const classes = classnames(
    className,
    "ExtraAreaButton",
    prepareModifiersForClassNames("ExtraAreaButton", {
      floating: isFloating,
      translucid: isTranslucid,
      primary: isPrimary,
      brand: isBrand,
      white: isWhite,
      extraAreaEnabled: clickableArea.length > 0,
    }),
  );
  useEffect(() => {
    const sides = ["Top", "Left", "Bottom", "Right"];
    clickableArea.forEach((value, index) => {
      buttonRef.current.style.setProperty(
        `--touchArea${sides[index]}`,
        getPixelValue(value),
      );
    });
  });

  return (
    <El className={classes} href={href} {...props} ref={buttonRef}>
      {children}
    </El>
  );
};

ExtraAreaButton.propTypes = propTypes;

export default ExtraAreaButton;
