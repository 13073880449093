import React from "react";
import PropTypes from "prop-types";

const ShortlistSvg = ({ fillColor = "#24272E", width = 18, height = 18 }) => {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 16.5625L7.9125 15.5725C4.05 12.07 1.5 9.76 1.5 6.925C1.5 4.615 3.315 2.8 5.625 2.8C6.93 2.8 8.1825 3.4075 9 4.3675C9.8175 3.4075 11.07 2.8 12.375 2.8C14.685 2.8 16.5 4.615 16.5 6.925C16.5 9.76 13.95 12.07 10.0875 15.58L9 16.5625Z"
        fill={fillColor}
      />
    </svg>
  );
};

ShortlistSvg.propTypes = {
  fillColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ShortlistSvg;
