import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button } from "../../../../../components/DesignSystem/Button/Button";
import Popover, { placements } from "../../../../../components/Popover/Popover";
import AccommodationTypeFilter from "./AccommodationTypeFilter";
import ApartmentSizeFilter from "./ApartmentSizeFilter";
import BedsFilter from "./BedsFilter";
import HouseRulesFilter from "./HouseRulesFilter";
import RoomFilter from "./RoomFilter";

const propTypes = {
  t: PropTypes.func.isRequired,
  query: PropTypes.object,
  isOpen: PropTypes.bool,
  onFilterSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  analytics: PropTypes.func,
  setMinRooms: PropTypes.func.isRequired,
  setHouseRules: PropTypes.func.isRequired,
  setAccommodationType: PropTypes.func.isRequired,
  minRooms: PropTypes.number.isRequired,
  houseRules: PropTypes.object.isRequired,
  homeType: PropTypes.string,
  hasFilters: PropTypes.bool,
  totalSingleBeds: PropTypes.number.isRequired,
  totalDoubleBeds: PropTypes.number.isRequired,
  setTotalSingleBeds: PropTypes.func.isRequired,
  setTotalDoubleBeds: PropTypes.func.isRequired,
};

const MoreFilters = ({
  t,
  query,
  isOpen,
  onFilterSelect,
  onSubmit,
  analytics,
  setMinRooms,
  setHouseRules,
  minRooms,
  totalSingleBeds,
  totalDoubleBeds,
  setTotalSingleBeds,
  setTotalDoubleBeds,
  houseRules,
  hasFilters,
  homeType,
  setAccommodationType,
}) => {
  const [minSize, setMinSize] = useState(parseInt(query?.minSize, 10) || 1);

  const clearAll = (evt) => {
    if (evt) {
      evt.preventDefault();
    }

    setMinRooms(1);
    setTotalSingleBeds(0);
    setTotalDoubleBeds(0);
    setHouseRules({ petsAllowed: false, smokingAllowed: false });
    setAccommodationType("");
    setMinSize(1);
  };

  const setDefaultValues = () => {
    setMinRooms(parseInt(query?.minRooms, 10) || 1);
    setHouseRules({
      petsAllowed: query?.petRules === "true" || false,
      smokingAllowed: query?.smokingRules === "true" || false,
    });
    setMinSize(parseInt(query?.minSize, 10) || 1);
    setTotalSingleBeds(parseInt(query?.totalSingleBeds, 10) || 0);
    setTotalDoubleBeds(parseInt(query?.totalDoubleBeds, 10) || 0);
    setAccommodationType(query?.homeType || "");
  };

  return (
    <Popover
      placement={placements.bottom}
      show={isOpen}
      onClose={() => onFilterSelect(null)}
      usePortal={false}
      around={
        <Button
          type="button"
          size="medium"
          colorVariant={hasFilters ? "brandPurple" : "white"}
          dataTestId="ListingFilter-moreFiltersButton"
          onClick={() => {
            if (!isOpen) {
              if (analytics) {
                analytics("click on More filters");
              }
              clearAll();
            }
            setDefaultValues();
            onFilterSelect("moreFilters");
          }}
          className={classnames("ListingFilterButton", {
            "ListingFilterButton--focus": isOpen,
          })}
        >
          {t("moreFilters")}
        </Button>
      }
      innerClassname="MoreFilters-popperInner"
    >
      <div className="MoreFilters">
        <div className="MoreFilters-section">
          <RoomFilter t={t} minRooms={minRooms} setMinRooms={setMinRooms} />
        </div>
        <div className="MoreFilters-section">
          <BedsFilter
            t={t}
            totalSingleBeds={totalSingleBeds}
            totalDoubleBeds={totalDoubleBeds}
            setTotalSingleBeds={setTotalSingleBeds}
            setTotalDoubleBeds={setTotalDoubleBeds}
          />
        </div>
        <div className="MoreFilters-section">
          <HouseRulesFilter
            t={t}
            rules={houseRules}
            setHouseRules={setHouseRules}
          />
        </div>
        <div className="MoreFilters-section">
          <AccommodationTypeFilter
            t={t}
            type={homeType}
            setAccommodationType={setAccommodationType}
          />
        </div>
        <div className="MoreFilters-section">
          <ApartmentSizeFilter
            t={t}
            minSize={minSize}
            setMinSize={setMinSize}
          />
        </div>
        <div className="MoreFilters-footer">
          <button
            type="button"
            className="MoreFilters-clearAllButton"
            onClick={() => {
              clearAll();
            }}
          >
            {t("clearAll")}
          </button>
          <Button
            type="button"
            className="MoreFilters-apply"
            onClick={onSubmit}
            dataTestId="ListingFilter-moreFiltersApply"
            size="medium"
          >
            {t("apply")}
          </Button>
        </div>
      </div>
    </Popover>
  );
};

MoreFilters.propTypes = propTypes;

export default MoreFilters;
