const COFUNDED_BY_THE_EU_LOGOS = {
  en: "/public/images/refugee-listings/logos/cofunded-by-the-eu_en.svg",
  de: "/public/images/refugee-listings/logos/cofunded-by-the-eu_de.svg",
  ru: "/public/images/refugee-listings/logos/cofunded-by-the-eu_ru.svg",
  uk: "/public/images/refugee-listings/logos/cofunded-by-the-eu_uk.svg",
};

export default (locale) => {
  return COFUNDED_BY_THE_EU_LOGOS[locale === "fr" ? "en" : locale];
};
