import React from "react";
import PropTypes from "prop-types";
import Img from "../../../../../components/Img/Img";

const propTypes = {
  alt: PropTypes.string,
  caption: PropTypes.string,
  text: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  region: PropTypes.string,
  h3s: PropTypes.array,
  isFirst: PropTypes.bool,
  isExtraContentHidden: PropTypes.bool,
  toggleExtraContentVisibility: PropTypes.func,
};

class CityDescriptionCard extends React.PureComponent {
  onClickReadMore = () => {
    this.props.toggleExtraContentVisibility();
  };

  containsAdditionalRows = () => this.props.h3s && this.props.h3s.length;

  render() {
    const {
      alt,
      caption,
      text,
      description,
      image,
      isFirst,
      isExtraContentHidden,
    } = this.props;

    return (
      <div className="CityDescriptionCard">
        <div className="CityDescriptionCard-mainTitle">
          <h2>{text}</h2>
        </div>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: description }}
          className="CityDescriptionCard-mainText CityDescriptionCard-richTextField"
        />
        <figure className="CityDescriptionCard-mainImage">
          <React.Fragment>
            <Img
              alt={alt}
              src={image.px1800}
              srcSet={`${image.px3600} 3600w, ${image.px1800} 1800w, ${image.px800} 800w, ${image.px400} 400w`}
            />
            {caption && <figcaption>{caption}</figcaption>}
          </React.Fragment>

          {isFirst ? (
            <button
              onClick={this.onClickReadMore}
              className="CityDescriptionCard-readMoreBtn"
            >
              <div>
                <i
                  className={`arrow ${isExtraContentHidden ? "down" : "up"}`}
                />
              </div>
              <span>Read More</span>
            </button>
          ) : null}
        </figure>
        {this.containsAdditionalRows() ? (
          <ul
            className={`CityDescriptionCard-additionalContent ${
              isExtraContentHidden
                ? "CityDescriptionCard-additionalContent--hidden"
                : ""
            }`}
          >
            {this.props.h3s.map((row) => {
              const isThereARowImage =
                row.image && row.image.px1800 && row.image.px800;

              return (
                <li
                  className="CityDescriptionCard-additionalContentRow"
                  key={row.text}
                >
                  <div className="CityDescriptionCard-additionalContentRowHeader">
                    <h3>{row.text}</h3>
                    <span
                      className="CityDescriptionCard-richTextFieldSmall"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: row.description }}
                    />
                  </div>

                  <ul className="CityDescriptionCard-additionalContentColumns">
                    {row.h4s.map((column) => (
                      <li
                        className="CityDescriptionCard-additionalContentColumn"
                        key={column.text}
                      >
                        <h4 className="CityDescriptionCard-additionalContentColumnTitle">
                          {column.text}
                        </h4>
                        <div
                          className="CityDescriptionCard-additionalContentColumnText CityDescriptionCard-richTextFieldSmall"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: column.body }}
                        />
                      </li>
                    ))}
                  </ul>
                  {isThereARowImage ? (
                    <figure className="CityDescriptionCard-additionalContentImage">
                      <React.Fragment>
                        <Img
                          alt={row.alt}
                          src={row.image.px1800}
                          srcSet={`${row.image.px3600} 3600w, ${row.image.px1800} 1800w, ${row.image.px800} 800w, ${row.image.px400} 400w`}
                        />
                        {row.caption && <figcaption>{row.caption}</figcaption>}
                      </React.Fragment>
                    </figure>
                  ) : null}
                </li>
              );
            })}
          </ul>
        ) : null}
      </div>
    );
  }
}
CityDescriptionCard.propTypes = propTypes;

export default CityDescriptionCard;
