// eslint-disable-next-line import/prefer-default-export
export const actions = {
  REGISTRATION_CHECK: "REGISTRATION_CHECK",
  USER_REGISTERED: "USER_REGISTERED",
  USER_NOT_REGISTERED: "USER_NOT_REGISTERED",
  SELECT_FEDERAL_STATE: "SELECT_FEDERAL_STATE",
  SELECT_MUNICIPALITY: "SELECT_MUNICIPALITY",
  GO_TO_PREVIOUS_STEP: "GO_TO_PREVIOUS_STEP",
  REOPEN_MODAL_WITH_PREVIOUS_SELECTIONS:
    "REOPEN_MODAL_WITH_PREVIOUS_SELECTIONS",
};
