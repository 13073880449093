import React, { useState } from "react";
import PropTypes from "prop-types";
import CityDescriptionCard from "./CityDescriptionCard";

const cityDescriptionPropTypes = {
  region: PropTypes.string,
  data: PropTypes.array,
};

const CityDescription = ({ data, region }) => {
  const [isExtraContentHidden, setIsExtraContentHidden] = useState(true);

  const toggleExtraContentVisibility = () => {
    setIsExtraContentHidden(!isExtraContentHidden);
  };

  return (
    <ul className="CityDescription">
      {data?.map((description, index) => {
        const isFirst = index === 0;
        return (
          <li
            className={
              !isFirst && isExtraContentHidden ? "CityDescription--hidden" : ""
            }
            key={description.text}
          >
            <CityDescriptionCard
              toggleExtraContentVisibility={toggleExtraContentVisibility}
              isFirst={isFirst}
              region={region}
              isExtraContentHidden={isExtraContentHidden}
              {...description}
            />
          </li>
        );
      })}
    </ul>
  );
};

CityDescription.propTypes = cityDescriptionPropTypes;

export default CityDescription;
