import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Rheostat from "rheostat";
import Histogram from "./Histogram";

import "./Slider.scss";

const propTypes = {
  showHistogram: PropTypes.bool,
  onChange: PropTypes.func,
  histogramRange: PropTypes.array,
  currentMinValue: PropTypes.number,
  currentMaxValue: PropTypes.number,
  maxCount: PropTypes.number,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  algorithm: PropTypes.shape({
    getValue: PropTypes.func,
    getPosition: PropTypes.func,
  }),
  disabled: PropTypes.bool,
};

const Slider = ({
  showHistogram,
  onChange,
  histogramRange,
  currentMinValue,
  currentMaxValue,
  maxCount,
  minValue,
  maxValue,
  values,
  algorithm,
  disabled,
}) => {
  return (
    <div
      className={classnames("Slider", { "Slider--disabled": disabled })}
      data-testid="histogram-slider"
    >
      {showHistogram && (
        <Histogram
          range={histogramRange}
          currentMinValue={currentMinValue}
          currentMaxValue={currentMaxValue}
          maxCount={maxCount}
        />
      )}
      <Rheostat
        disabled={disabled}
        values={disabled ? [0, 100] : values}
        min={disabled ? 0 : minValue}
        max={disabled ? 100 : maxValue}
        onValuesUpdated={onChange}
        algorithm={algorithm}
      />
    </div>
  );
};

Slider.propTypes = propTypes;

export default Slider;
