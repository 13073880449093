import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import useMedia from "../../hooks/useMedia";
import { useI18n } from "../../spages/spa/context/I18nContext";
import { urlMaker } from "../../utils/url";
import ArrowRight from "../ArrowRight/ArrowRight";
import Badge, { badgeVariants } from "../Badge/Badge";
import { Button, ClientSideLink } from "../DesignSystem/Button/Button";
import { IconClientSideLink } from "../DesignSystem/IconButton/IconButton";
import MoreInfoCircularIcon from "../Icons/MoreInfoCircular";
import Popover, { placements } from "../Popover/Popover";

import "./ServicedApartmentBadge.scss";

const ServicedApartmentBadge = ({
  popoverWidth,
  city,
  query,
  slug,
  className,
}) => {
  const isSmallScreen = useMedia("(max-width: 562px)");
  const [showPopover, setShowPopover] = useState(false);
  const { t, lang } = useI18n();
  const switchPopover = (e) => {
    setShowPopover(!showPopover);
    e.stopPropagation();
  };
  const closePopover = (e) => {
    setShowPopover(false);
    e.stopPropagation();
  };
  const url = urlMaker(lang);

  const currentUrlPath =
    typeof window !== "undefined" ? window?.location.pathname : "";
  const showServicedApartmentsLink = slug && !currentUrlPath.includes(slug);

  const servicedApartmentCategoriesLink = useMemo(
    () =>
      url(
        "categories",
        {
          region: city?.toLowerCase(),
          category: slug,
          lang,
        },
        { query },
      ),
    [city, lang, query, slug, url],
  );

  return (
    <Badge
      className={classNames(
        "Badge--servicedApartments Badge--flex Badge--color-text",
        className,
      )}
      dataTestId="ServicedApartmentsBadge"
      variant={badgeVariants.whiteTransparent}
      text={t("components.ServicedApartmentBadge.text")}
      rightIcon={
        <Popover
          preventAutofocus
          placement={placements.bottom}
          showInDrawer={isSmallScreen}
          popoverContainerStyle={{
            maxWidth: popoverWidth,
            zIndex: 100,
          }}
          innerClassname="Badge--servicedApartments--popoverInner"
          overrideReferenceClassname="Badge--servicedApartments--popper-reference"
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, -3],
              },
            },
          ]}
          drawerChildrenClassname="Badge--servicedApartments-drawer"
          around={
            <MoreInfoCircularIcon
              className="Badge--servicedApartments-infoIcon"
              onClick={switchPopover}
              data-testid="ServicedApartmentBadgeIcon"
            />
          }
          onClose={closePopover}
          show={showPopover}
        >
          <div
            data-testid="ServicedApartmentBadgePopover"
            className={classNames("Badge--servicedApartments-body", {
              "Badge--servicedApartments-drawerBody": isSmallScreen,
            })}
          >
            <span>{t("components.ServicedApartmentBadge.popover.body")}</span>
            {isSmallScreen ? (
              <div className="Badge--servicedApartments-drawerBody-buttons">
                {showServicedApartmentsLink && (
                  <ClientSideLink
                    name="ServicedApartmentBadgeGoLink"
                    to={servicedApartmentCategoriesLink}
                    onClick={closePopover}
                    widthVariant="fullWidth"
                    dataTestId="ServicedApartmentBadgeGoLink"
                  >
                    {t("components.ServicedApartmentBadge.popover.buttons.go")}
                  </ClientSideLink>
                )}
                <Button
                  name="ServicedApartmentBadgeCloseButton"
                  colorVariant="secondary"
                  widthVariant="fullWidth"
                  onClick={closePopover}
                >
                  {t("components.ServicedApartmentBadge.popover.buttons.close")}
                </Button>
              </div>
            ) : (
              showServicedApartmentsLink && (
                <IconClientSideLink
                  name="ServicedApartmentBadgeGoLink"
                  dataTestId="ServicedApartmentBadgeGoLink"
                  to={servicedApartmentCategoriesLink}
                  size="medium"
                  onClick={closePopover}
                  alignIconToTheRight
                  icon={<ArrowRight fillColor="#343434" />}
                  label={t(
                    "components.ServicedApartmentBadge.popover.buttons.go",
                  )}
                  className="Badge--servicedApartments--popover-button"
                />
              )
            )}
          </div>
        </Popover>
      }
    />
  );
};

ServicedApartmentBadge.propTypes = {
  popoverWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  city: PropTypes.string,
  query: PropTypes.object,
  slug: PropTypes.string,
  className: PropTypes.string,
};
export default ServicedApartmentBadge;
