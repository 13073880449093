import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import BubbleCount from "../../../../../components/BubbleCount/BubbleCount";
import { Button } from "../../../../../components/DesignSystem/Button/Button";
import Popover, { placements } from "../../../../../components/Popover/Popover";
import StyledCheckbox from "../../../../../components/StyledCheckbox/StyledCheckbox";

const propTypes = {
  t: PropTypes.func.isRequired,
  defaultAmenities: PropTypes.array.isRequired,
  onFilterSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  amenitiesMap: PropTypes.object.isRequired,
  hasFilters: PropTypes.bool,
  amenitiesToggle: PropTypes.func.isRequired,
};

const AmenitiesFilter = ({
  t,
  defaultAmenities,
  opened,
  onFilterSelect,
  onSubmit,
  onClear,
  amenitiesMap,
  hasFilters,
  amenitiesToggle,
}) => (
  <Popover
    placement={placements.bottom}
    show={opened}
    onClose={() => onFilterSelect(null)}
    usePortal={false}
    around={
      <Button
        colorVariant={hasFilters ? "brandPurple" : "white"}
        size="medium"
        type="button"
        className={classnames("ListingFilterButton", {
          "ListingFilterButton--focus": opened,
        })}
        dataTestId="ListingFilter-amenitiesButton"
        onClick={() => {
          if (!opened) {
            onClear();
          }
          onFilterSelect("amenities");
        }}
      >
        <span className="ListingFilterButton-text">
          {t("spages.listing.ListingDetailsPageBase.section.amenities")}
        </span>
        {hasFilters && <BubbleCount count={defaultAmenities.length} />}
      </Button>
    }
    innerClassname="ListingFilter-popperInner"
  >
    <div className="AmenitiesFilter">
      <h3 className="AmenitiesFilter-heading">
        {t("pages.ListingsPage.filters.amenities.info.wifi")}
      </h3>
      <input hidden name="amenities" />
      <ul className="AmenitiesFilter-rowList">
        {[...amenitiesMap.entries()].map(([amenity, checked]) => {
          return (
            <li data-testid={`amenities-${amenity}`} key={amenity}>
              <StyledCheckbox
                label={t(`listings.amenities.${amenity}`)}
                checked={checked}
                onChange={(event) =>
                  amenitiesToggle(amenity, event.target.checked)
                }
              />
            </li>
          );
        })}
      </ul>
    </div>
    <div className="AmenitiesFilter-buttonsContainer">
      <button
        type="button"
        className="AmenitiesFilter-clearBtn"
        data-testid="AmenitiesFilter-clearBtn"
        onClick={onClear}
        disabled={[...amenitiesMap.entries()].every(([, checked]) => !checked)}
      >
        {t("clear")}
      </button>
      <Button
        colorVariant="primary"
        type="button"
        className="AmenitiesFilter-submitBtn"
        dataTestId="AmenitiesFilter-submitBtn"
        onClick={onSubmit}
        size="medium"
      >
        {t("apply")}
      </Button>
    </div>
  </Popover>
);

AmenitiesFilter.propTypes = propTypes;

export default AmenitiesFilter;
