import React from "react";
import PropTypes from "prop-types";
import CustomDropdownSelect from "../../CustomDropdownSelect/CustomDropdownSelect";
import { Button } from "../../DesignSystem/Button/Button";
import WarningIconInCircle from "../../Icons/WarningIconInCircle";
import "./Step.scss";

const Step = ({
  stepIcon,
  stepTitle,
  stepBody,
  dropdownProps,
  error,
  primaryButtonIsLoading,
  primaryButtonDisabled,
  primaryButtonLabel,
  secondaryButtonLabel,
  onClickPrimaryButton,
  onClickSecondaryButton,
  buttonsWidthVariant = "fullWidth",
  primaryButtonDataTestId,
  secondaryButtonDataTestId,
  dataTestId,
}) => {
  const stepIconToShow = error ? <WarningIconInCircle /> : stepIcon;

  return (
    <section className="Step" data-testid={dataTestId}>
      <div className="Step-IconContainer">{stepIconToShow}</div>
      <h2 className="Step-Title">{stepTitle}</h2>
      {stepBody && <div className="Step-Body">{stepBody}</div>}
      {dropdownProps && (
        <CustomDropdownSelect dropdownProps={dropdownProps} error={error} />
      )}
      <div className="Step-ButtonContainer">
        {secondaryButtonLabel && (
          <Button
            onClick={onClickSecondaryButton}
            colorVariant="secondaryBlue"
            widthVariant={buttonsWidthVariant}
            dataTestId={secondaryButtonDataTestId}
          >
            {secondaryButtonLabel}
          </Button>
        )}
        {primaryButtonLabel && (
          <Button
            onClick={onClickPrimaryButton}
            disabled={primaryButtonDisabled}
            loading={primaryButtonIsLoading}
            colorVariant="brandPurple"
            widthVariant={buttonsWidthVariant}
            dataTestId={primaryButtonDataTestId}
          >
            {primaryButtonLabel}
          </Button>
        )}
      </div>
    </section>
  );
};

Step.propTypes = {
  stepTitle: PropTypes.string.isRequired,
  stepBody: PropTypes.node,
  stepIcon: PropTypes.node,
  error: PropTypes.bool,
  dropdownProps: PropTypes.shape({
    dropdownOptions: PropTypes.arrayOf(PropTypes.object),
    handleChange: PropTypes.func,
    selectedValue: PropTypes.any,
    inputPlaceholder: PropTypes.string.isRequired,
    valueParamName: PropTypes.string.isRequired,
    textParamName: PropTypes.string.isRequired,
  }),
  primaryButtonIsLoading: PropTypes.bool,
  primaryButtonDisabled: PropTypes.bool,
  primaryButtonLabel: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  onClickPrimaryButton: PropTypes.func,
  onClickSecondaryButton: PropTypes.func,
  buttonsWidthVariant: PropTypes.oneOf(["fullWidth", "growWithText"]),
  primaryButtonDataTestId: PropTypes.string,
  secondaryButtonDataTestId: PropTypes.string,
  dataTestId: PropTypes.string,
};

export default Step;
