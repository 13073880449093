import "./CustomDropdownSelect.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Triangle from "../Icons/Triangle";

const DropdownTrigger = ({
  isSearchable,
  inputRef,
  value,
  error,
  inputPlaceholder,
  setIsDropdownActive,
  handleValueChange,
}) => {
  if (isSearchable) {
    return (
      <input
        ref={inputRef}
        className={classnames("CustomDropdownSelect-input", {
          "CustomDropdownSelect-input--error": error,
        })}
        placeholder={inputPlaceholder}
        onFocus={() => setIsDropdownActive(true)}
        value={value}
        onChange={(e) => {
          handleValueChange(e.target.value);
        }}
        data-testid="CustomDropdownSelect-input"
      />
    );
  }
  return (
    <div
      ref={inputRef}
      onClick={(e) => {
        e.stopPropagation();
        setIsDropdownActive(true);
      }}
      className={classnames(
        "CustomDropdownSelect-input",
        "CustomDropdownSelect-trigger",
        {
          "CustomDropdownSelect-input--error": error,
        },
      )}
      data-testid="CustomDropdownSelect-trigger"
    >
      {value || inputPlaceholder}
    </div>
  );
};

DropdownTrigger.propTypes = {
  isSearchable: PropTypes.bool,
  inputRef: PropTypes.object.isRequired,
  value: PropTypes.string,
  error: PropTypes.bool,
  inputPlaceholder: PropTypes.string,
  setIsDropdownActive: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
};

const CustomDropdownSelect = ({
  isSearchable = true,
  dropdownProps,
  error,
}) => {
  const {
    dropdownOptions = [],
    handleChange,
    selectedValue,
    inputPlaceholder,
    valueParamName,
    textParamName,
  } = dropdownProps;
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [query, setQuery] = useState("");
  const inputRef = useRef(null);
  const iconRef = useRef(null);

  const filter = (items) => {
    if (!isSearchable) return items;
    return items.filter((item) => {
      return item[textParamName].toLowerCase().includes(query.toLowerCase());
    });
  };

  const getValueText = (selectedValue) => {
    const selectedOption = dropdownOptions.find(
      (item) => item[valueParamName] === selectedValue,
    );
    return selectedOption?.[textParamName];
  };

  const value = query || getValueText(selectedValue) || "";

  const onClickOutsideHandler = useCallback(
    (e) => {
      if (inputRef.current.contains(e.target) && isDropdownActive) {
        return;
      }
      if (iconRef.current.contains(e.target)) return;

      e.stopPropagation();
      return setIsDropdownActive(false);
    },
    [isDropdownActive],
  );

  useEffect(() => {
    window.addEventListener("click", onClickOutsideHandler);

    return () => {
      window.removeEventListener("click", onClickOutsideHandler);
    };
  }, [onClickOutsideHandler]);

  return (
    <React.Fragment>
      <div
        className="CustomDropdownSelect-container"
        data-testid="CustomDropdownSelect"
      >
        <DropdownTrigger
          isSearchable={isSearchable}
          inputRef={inputRef}
          value={value}
          error={error}
          inputPlaceholder={inputPlaceholder}
          setIsDropdownActive={setIsDropdownActive}
          handleValueChange={(targetValue) => {
            setQuery(targetValue);
            handleChange(null);
          }}
        />
        <ul
          className={classnames("CustomDropdownSelect-list", {
            "CustomDropdownSelect-list--active": isDropdownActive,
          })}
          data-testid="CustomDropdownSelect-list"
        >
          {isDropdownActive && (
            <React.Fragment>
              {filter(dropdownOptions).map((item) => {
                return (
                  <li
                    data-testid={`CustomDropdownSelect-listItem-${
                      item.text || item.id
                    }`}
                    key={item[valueParamName]}
                    className="CustomDropdownSelect-listItem"
                    value={item[valueParamName]}
                    onClick={() => {
                      handleChange(item[valueParamName]);
                      setQuery("");
                      setIsDropdownActive(false);
                    }}
                  >
                    {item[textParamName]}
                  </li>
                );
              })}
            </React.Fragment>
          )}
        </ul>
        <div
          className="CustomDropdownSelect-icon"
          ref={iconRef}
          onClick={() => setIsDropdownActive(!isDropdownActive)}
          data-testid="CustomDropdownSelect-icon"
        >
          <div className="CustomDropdownSelect-triangle">
            <Triangle />
          </div>
        </div>
      </div>
      {error?.message && (
        <p
          className="CustomDropdownSelect-errorMessage"
          data-testid="CustomDropdownSelect-errorMessage"
        >
          {error.message}
        </p>
      )}
    </React.Fragment>
  );
};

CustomDropdownSelect.propTypes = {
  dropdownProps: PropTypes.shape({
    dropdownOptions: PropTypes.arrayOf(PropTypes.object),
    handleChange: PropTypes.func,
    selectedValue: PropTypes.any,
    inputPlaceholder: PropTypes.string.isRequired,
    valueParamName: PropTypes.string.isRequired,
    textParamName: PropTypes.string.isRequired,
  }).isRequired,
  error: PropTypes.shape({ message: PropTypes.string }),
  isSearchable: PropTypes.bool,
};

export default CustomDropdownSelect;
