import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as amplitude from "@amplitude/analytics-browser";
import { misc } from "@wunderflats/constants";
import { useI18n } from "../../../spages/spa/context/I18nContext";
import { getBboxStringFromArray } from "../../../utils/geocodingHelpers";
import MarkerIcon from "../../Icons/MarkerIcon";
import { actions } from "../constants";
import Step from "./Step";

const MunicipalitySelection = ({
  dispatch,
  data,
  currentStep,
  applyRefugeeTeritorialSearch,
}) => {
  const { t } = useI18n();

  const [selectedMunicipality, setSelectedMunicipality] = useState(
    data.municipality || "",
  );
  const { listOfMunicipalities } = data;

  const checkIfCurrentMunicipalityIsAvailable = useCallback(() => {
    const isCurrentMunicipalityAvailable = listOfMunicipalities.find(
      (municipality) => municipality.id === selectedMunicipality,
    );
    if (!isCurrentMunicipalityAvailable) setSelectedMunicipality(null);
  }, [listOfMunicipalities, selectedMunicipality]);

  // if municipality value already exists, check if it's available in the list for the current federal state
  // if yes, then input is being prefilled
  useEffect(
    () => checkIfCurrentMunicipalityIsAvailable(),
    [checkIfCurrentMunicipalityIsAvailable, listOfMunicipalities],
  );

  const applyMunicipalitySearch = () => {
    amplitude.track("Refugee Flow Button Clicked", {
      button_description: "Apply Municipality selection",
      step_name: currentStep,
    });

    dispatch({
      type: actions.SELECT_MUNICIPALITY,
      municipality: selectedMunicipality,
    });

    // Get the selected federal state's bbox
    const { bbox } = listOfMunicipalities.find(
      (municipality) => municipality.id === selectedMunicipality,
    );

    applyRefugeeTeritorialSearch({
      federalState: data.federalState,
      municipality: selectedMunicipality,
      bbox: getBboxStringFromArray(bbox),
    });
  };

  const applyFederalStateSearch = () => {
    amplitude.track("Refugee Flow Button Clicked", {
      button_description: "No Municipality assigned",
      step_name: currentStep,
    });

    const federalState = misc.statesOfGermany.STATES_OF_GERMANY_OPTIONS.find(
      (option) => option.value === data.federalState,
    );

    applyRefugeeTeritorialSearch({
      federalState: data.federalState,
      municipality: null,
      bbox: getBboxStringFromArray(federalState?.bbox),
    });
  };

  const handleChange = (value) => {
    setSelectedMunicipality(value);
  };

  const dropdownProps = {
    dropdownOptions: listOfMunicipalities,
    handleChange,
    selectedValue: selectedMunicipality,
    inputPlaceholder: t("select.placeholder"),
    valueParamName: "id",
    textParamName: "name",
  };

  return (
    <div>
      <Step
        stepTitle={t(
          "components.refugeeDistributionModal.pleaseSelectSMunicipality",
        )}
        stepIcon={<MarkerIcon />}
        dropdownProps={dropdownProps}
        primaryButtonLabel={t("apply")}
        onClickPrimaryButton={applyMunicipalitySearch}
        primaryButtonDisabled={!selectedMunicipality}
        secondaryButtonLabel={t(
          "components.refugeeDistributionModal.noAssignedMunicipality",
        )}
        onClickSecondaryButton={applyFederalStateSearch}
        buttonsWidthVariant="growWithText"
        dataTestId="MunicipalitySelectionStep"
        primaryButtonDataTestId="MunicipalitySelectionStep-applyButton"
        secondaryButtonDataTestId="MunicipalitySelectionStep-noMunicipalityButton"
      />
    </div>
  );
};

MunicipalitySelection.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.shape({
    federalState: PropTypes.string.isRequired,
    municipality: PropTypes.string,
    listOfMunicipalities: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
  currentStep: PropTypes.string.isRequired,
  applyRefugeeTeritorialSearch: PropTypes.func,
};

export default MunicipalitySelection;
