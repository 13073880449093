import React from "react";
import PropTypes from "prop-types";
import { canUseDOM } from "../../utils/dom";

const defaultObserverProps = { rootMargin: "100px" };

const ComponentLazyLoad = ({
  children,
  wrapperClassName,
  observerProps = defaultObserverProps,
}) => {
  const isIntersectionObserverSupported =
    canUseDOM() && "IntersectionObserver" in window;

  const innerComponentRef = React.useRef();
  const [shouldLoad, setShouldLoad] = React.useState(
    canUseDOM() ? !isIntersectionObserverSupported : false,
  );

  React.useEffect(() => {
    const isIntersectionObserverSupported = "IntersectionObserver" in window;
    let observer = null;
    if (isIntersectionObserverSupported) {
      observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setShouldLoad(true);
          observer.disconnect();
        }
      }, observerProps);

      if (innerComponentRef.current) {
        observer.observe(innerComponentRef.current);
      }
    } else {
      setShouldLoad(true);
    }

    return () => {
      if (isIntersectionObserverSupported && observer) {
        observer.disconnect();
      }
    };
  }, [observerProps]);

  return (
    <div className={wrapperClassName} ref={innerComponentRef}>
      {shouldLoad && children}
    </div>
  );
};

const propTypes = {
  children: PropTypes.any.isRequired,
  wrapperClassName: PropTypes.string,
  observerProps: PropTypes.object,
};

ComponentLazyLoad.propTypes = propTypes;

export default ComponentLazyLoad;
