export const langLocaleMap = {
  en: "en-US",
  de: "de-DE",
  fr: "fr-FR",
  uk: "uk-UA",
  ru: "ru-RU",
  es: "es-ES",
  pt: "pt-PT",
  pl: "pl-PL",
  tr: "tr-TR",
  it: "it-IT",
};

export const defaultLocale = "en-US";

export const toEuro = function toEuro(cents) {
  return Math.round(cents / 100);
};

export const toCents = function toCents(euro) {
  return Math.round(euro * 100);
};

export const toEuroFixed = function toEuroFixed(cents) {
  return Number((cents / 100).toFixed(2)).toLocaleString("de-DE");
};

export const formatCurrency = function formatCurrency({
  cents,
  lang,
  fixed = false,
}) {
  const locale = langLocaleMap[lang] || defaultLocale;
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: fixed ? 2 : 0,
  });

  return formatter.format(cents / 100);
};
