import React from "react";
import PropTypes from "prop-types";

const MarkerIcon = ({ width = 60, height = 60, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      {...props}
    >
      <path
        fill="#9AB4DE"
        fillOpacity=".2"
        stroke="#9AB4DE"
        d="M59.5 29.331c0 15.912-13.197 28.831-29.5 28.831S.5 45.243.5 29.331C.5 13.419 13.697.5 30 .5s29.5 12.919 29.5 28.831Z"
      />
      <path
        fill="#170C37"
        fillRule="evenodd"
        d="M29.5 17.066c-5.002 0-9.048 3.961-9.048 8.858 0 1.564.553 3.253 1.465 4.958.908 1.696 2.133 3.337 3.382 4.782a43.762 43.762 0 0 0 4.201 4.217 43.762 43.762 0 0 0 4.201-4.217c1.25-1.445 2.474-3.086 3.382-4.782.912-1.705 1.465-3.394 1.465-4.958 0-4.88-4.048-8.858-9.048-8.858Zm0 24.402c-.776.93-.776.929-.777.929l-.003-.003-.007-.005-.025-.021a42.552 42.552 0 0 1-1.632-1.425 46.182 46.182 0 0 1-3.629-3.729c-1.32-1.526-2.663-3.315-3.682-5.218C18.731 30.1 18 28.017 18 25.924c0-6.222 5.144-11.258 11.5-11.258 6.357 0 11.5 5.055 11.5 11.258 0 2.093-.731 4.176-1.745 6.072-1.019 1.903-2.363 3.692-3.682 5.218a46.182 46.182 0 0 1-5.168 5.078l-.093.076-.025.02-.007.006-.002.002s-.002.001-.778-.928Zm0 0 .776.93-.776.62-.777-.621.777-.929Z"
        clipRule="evenodd"
      />
      <path
        fill="#10224A"
        d="M30 31.287c2.761 0 5-2.408 5-5.378s-2.239-5.377-5-5.377-5 2.407-5 5.377c0 2.97 2.239 5.378 5 5.378Z"
      />
    </svg>
  );
};

MarkerIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default MarkerIcon;
