import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { Button } from "../../../../../components/DesignSystem/Button/Button";
import {
  getMaxBookingDurationInMonthsByCityName,
  isBookingPeriodValid,
} from "../../../../../utils/listing-utils";

dayjs.extend(isSameOrBefore);

const propTypes = {
  t: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  query: PropTypes.object,
  region: PropTypes.string.isRequired,
  minBookingDuration: PropTypes.number.isRequired,
};

const getValidFromDate = (currentFromDate) => {
  const nowDate = dayjs.utc().format("YYYY-MM-DD");
  if (!currentFromDate || dayjs.utc(currentFromDate).isBefore(nowDate)) {
    return nowDate;
  }

  return currentFromDate;
};

const DatesInfoBanner = ({
  t,
  onChangeFilter,
  query,
  region,
  minBookingDuration,
}) => {
  // When region enhancement enabled - we get city name with special characters from Mapbox API
  if (region === "köln") {
    region = "koeln";
  }

  if (
    isBookingPeriodValid(query.from, query.to, region) ||
    minBookingDuration === 1
  ) {
    return null;
  }

  const customMaxBookingDuration =
    getMaxBookingDurationInMonthsByCityName(region);

  const handleButtonClick = () => {
    const from = getValidFromDate(query.from);
    const to = dayjs
      .utc(from)
      .add(minBookingDuration, "months")
      .format("YYYY-MM-DD");

    const newQuery = { ...query, from, to };
    onChangeFilter(newQuery);
  };

  return (
    <aside className="DatesInfoBanner">
      <div className="DatesInfoBanner-outer">
        <header className="DatesInfoBanner-outerText">
          <h1 className="DatesInfoBanner-title">
            {t("pages.ListingsPage.bookingDurationNotification.title")}
          </h1>
          <h2 className="DatesInfoBanner-text">
            {customMaxBookingDuration
              ? t("pages.ListingsPage.maxBookingDurationNotification.text", {
                  region: t(`regions.${region}`),
                  n: customMaxBookingDuration,
                })
              : t("pages.ListingsPage.minBookingDurationNotification.text", {
                  region: t(`regions.${region}`),
                  n: minBookingDuration,
                })}
          </h2>
        </header>
        <Button
          colorVariant="primary"
          className="DatesInfoBanner-cta"
          onClick={handleButtonClick}
          size="medium"
        >
          {t("pages.ListingsPage.bookingDurationNotification.ctaLabel")}
        </Button>
      </div>
    </aside>
  );
};

DatesInfoBanner.propTypes = propTypes;

export default DatesInfoBanner;
