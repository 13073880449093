import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Button } from "../../../../../../components/DesignSystem/Button/Button";
import Input from "../../../../../../components/Input/Input";
import Popover, {
  placements,
} from "../../../../../../components/Popover/Popover";
import StyledCheckbox from "../../../../../../components/StyledCheckbox/StyledCheckbox";
import PriceFilterContainer from "./PriceFilterContainer";
import PriceFilterLoading from "./PriceFilterLoading";

const propTypes = {
  t: PropTypes.func.isRequired,
  formRef: PropTypes.object,
  minPrice: PropTypes.number,
  maxPrice: PropTypes.number,
  isNoDepositFilterOn: PropTypes.bool,
  regionSlug: PropTypes.string,
  query: PropTypes.object,
  onSubmit: PropTypes.func,
  onFilterSelect: PropTypes.func.isRequired,
  opened: PropTypes.bool,
  hasFilters: PropTypes.bool,
};

const PriceFilter = ({
  t,
  opened,
  formRef,
  minPrice,
  maxPrice,
  isNoDepositFilterOn,
  regionSlug,
  query,
  onSubmit,
  onFilterSelect,
  hasFilters,
}) => (
  <Popover
    placement={placements.bottom}
    show={opened}
    onClose={() => onFilterSelect(null)}
    usePortal={false}
    around={
      <Button
        type="button"
        size="medium"
        className={classnames("ListingFilterButton", {
          "ListingFilterButton--focus": opened,
        })}
        colorVariant={hasFilters ? "brandPurple" : "white"}
        dataTestId="ListingFilter-priceButton"
        onClick={() => onFilterSelect("price")}
      >
        {t("prices")}
      </Button>
    }
    innerClassname="ListingFilter-popperInner"
  >
    <PriceFilterContainer
      minPrice={minPrice}
      maxPrice={maxPrice}
      isNoDepositFilterOn={isNoDepositFilterOn}
      regionSlug={regionSlug}
      className="PriceFilter"
      query={query}
      LoadingComponent={PriceFilterLoading}
    >
      {(
        rangeMin,
        rangeMax,
        noDeposit,
        t,
        setRangeMin,
        setRangeMax,
        setNoDeposit,
        resetValues,
        onBlurValidation,
      ) => (
        <React.Fragment>
          <p className="PriceFilter-caption">
            {t("pages.ListingsPage.priceFilter.pricePerMonth")}
          </p>
          <span className="PriceFilter-priceFieldsOuter">
            <input name="minPrice" type="hidden" defaultValue={rangeMin} />
            <input name="maxPrice" type="hidden" defaultValue={rangeMax} />
            <Input
              prefix="€"
              label={t("min")}
              labelFor="minPriceInput"
              name="minPriceTemp"
              type="text"
              value={rangeMin}
              onChange={(evt) => {
                setRangeMin(evt.target.value);
              }}
              hideSpinners
              onBlur={onBlurValidation}
            />
            <span className="PriceFilter-inputsSpacer">-</span>
            <Input
              prefix="€"
              label={t("max")}
              labelFor="maxPriceInput"
              name="maxPriceTemp"
              type="text"
              value={rangeMax}
              onChange={(evt) => setRangeMax(evt.target.value)}
              hideSpinners
              onBlur={onBlurValidation}
            />
          </span>
          <StyledCheckbox
            className="PriceFilter-depositCheckbox"
            data-testid="PriceFilter-depositCheckbox"
            label={t("pages.ListingsPage.filters.noDepositLabel")}
            name="noDeposit"
            checked={noDeposit}
            onChange={(event) => {
              setNoDeposit(!!event.target.checked);
            }}
          />
          <div className="PriceFilter-buttons">
            <button
              className="PriceFilter-resetValuesButton"
              type="button"
              onClick={() => resetValues()}
            >
              {t("clearBudget")}
            </button>
            <Button
              className="PriceFilter-submitButton"
              dataTestId="PriceFilter-submitButton"
              onClick={(e) => {
                e.preventDefault();
                formRef.minPrice.value = rangeMin;
                formRef.maxPrice.value = rangeMax;
                formRef.minPriceTemp.value = minPrice;
                formRef.maxPriceTemp.value = maxPrice;
                formRef.noDeposit.checked = noDeposit;
                onSubmit(formRef);
              }}
            >
              {t("apply")}
            </Button>
          </div>
        </React.Fragment>
      )}
    </PriceFilterContainer>
  </Popover>
);

PriceFilter.propTypes = propTypes;

export default PriceFilter;
