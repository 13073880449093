import React from "react";
import PropTypes from "prop-types";
import getDocumentHeight from "../../utils/document-height";
import scrollPosition from "../../utils/scroll-position";
import debounce from "./debounce";

const HIDDEN_CLASS_NAME = "StickyHeader--hidden";

const showElement = (element) => element.classList.remove(HIDDEN_CLASS_NAME);
const hideElement = (element) => element.classList.add(HIDDEN_CLASS_NAME);

const hideThresholdExceeded = (
  currentScrollY,
  lastKnownScrollY,
  hideThreshold,
) => {
  return Math.abs(currentScrollY - lastKnownScrollY) >= hideThreshold;
};

const StickyHeader = (WrappedComponent) => {
  const WrapperComponent = ({
    autoHide = false,
    hideThreshold = 10,
    ...props
  }) => {
    const container = React.useRef(null);

    React.useEffect(() => {
      let lastKnownScrollY = 0;
      let isFirstScrollEvent = true;
      const debouncedListenerCallback = debounce(() => {
        if (isFirstScrollEvent) {
          isFirstScrollEvent = false;
          return;
        }
        const currentScrollY = scrollPosition().y;
        const scrollDirection =
          currentScrollY < lastKnownScrollY ? "up" : "down";

        // Scrolled to the very top.
        // We are showing the header in this case.
        if (currentScrollY <= 0) {
          showElement(container.current);
        }

        // Direction controls
        if (
          scrollDirection === "up" &&
          hideThresholdExceeded(currentScrollY, lastKnownScrollY, hideThreshold)
        ) {
          showElement(container.current);
        } else if (
          scrollDirection === "down" &&
          hideThresholdExceeded(currentScrollY, lastKnownScrollY, hideThreshold)
        ) {
          hideElement(container.current);
        }

        // Scrolled to the very bottom.
        // We are showing the header in this case also.
        if (currentScrollY + window.innerHeight >= getDocumentHeight()) {
          showElement(container.current);
        }

        lastKnownScrollY = currentScrollY;
      });

      if (autoHide) {
        window.addEventListener("scroll", debouncedListenerCallback);
      }

      return () => {
        // Detach the event listener from the browser.
        if (autoHide) {
          window.removeEventListener("scroll", debouncedListenerCallback);
        }
      };
    });

    return (
      <div className="StickyHeader" ref={container}>
        <WrappedComponent {...props} />
      </div>
    );
  };

  WrapperComponent.propTypes = {
    autoHide: PropTypes.bool,
    hideThreshold: PropTypes.number,
  };

  return WrapperComponent;
};

export default StickyHeader;
