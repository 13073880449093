import React from "react";
import PropTypes from "prop-types";
import * as amplitude from "@amplitude/analytics-browser";
import { useI18n } from "../../../spages/spa/context/I18nContext";
import HomeIconInCircle from "../../Icons/HomeIconInCircle";
import { actions } from "../constants";
import Step from "./Step";
import "./RegistrationCheckStep.scss";

const RegistrationCheckStep = ({ dispatch, currentStep, scrollToCenter }) => {
  const onClickYes = () => {
    amplitude.track("Refugee Flow Button Clicked", {
      button_description: "Place of residence assigned",
      step_name: currentStep,
    });
    scrollToCenter();
    dispatch({ type: actions.USER_REGISTERED });
  };
  const onClickNo = () => {
    amplitude.track("Refugee Flow Button Clicked", {
      button_description: "No place of residence assigned",
      step_name: currentStep,
    });
    scrollToCenter();
    dispatch({ type: actions.USER_NOT_REGISTERED });
  };
  const { t } = useI18n();

  return (
    <Step
      stepIcon={<HomeIconInCircle />}
      stepTitle={t(
        "components.refugeeDistributionModal.registrationCheckStep.title",
      )}
      stepBody={
        <React.Fragment>
          <p>
            {t(
              "components.refugeeDistributionModal.registrationCheckStep.body",
            )}
          </p>
          <p className="RegistrationCheckStep-prompt">
            {t(
              "components.refugeeDistributionModal.registrationCheckStep.prompt",
            )}
          </p>
        </React.Fragment>
      }
      onClickPrimaryButton={onClickYes}
      primaryButtonLabel={t("yes")}
      primaryButtonDataTestId="RegistrationCheckStep-YesButton"
      onClickSecondaryButton={onClickNo}
      secondaryButtonLabel={t("no")}
      secondaryButtonDataTestId="RegistrationCheckStep-NoButton"
      dataTestId="RegistrationCheckStep"
    />
  );
};

RegistrationCheckStep.propTypes = {
  dispatch: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
  scrollToCenter: PropTypes.func,
};

export default RegistrationCheckStep;
