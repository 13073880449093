const webSafeFonts = [
  "Georgia",
  "Palatino",
  "Palatino Linotype",
  "Book Antiqua",
  "Times New Roman",
  "Times",
  "Arial",
  "Helvetica",
  "Arial Black",
  "Gadget",
  "Comic Sans MS",
  "Impact",
  "Charcoal",
  "Lucida Sans Unicode",
  "Lucida Grande",
  "Tahoma",
  "Geneva",
  "Trebuchet MS",
  "Verdana",
  "Courier New",
  "Courier",
  "Lucida Console",
  "Monaco",
];

export default webSafeFonts;
