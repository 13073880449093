import React, { useState } from "react";
import PropTypes from "prop-types";
import CoverImage from "../CoverImage/CoverImage";
import Img from "../Img/Img";
import SvgSpinner from "../SvgSpinner/SvgSpinner";
import GalleryNavigation from "./GalleryNavigation";
import { setImageRefLoading } from "./utils";

export const DesktopImage = React.forwardRef(
  (
    { src, srcSet, sectionLabel, selectNext, selectPrevious, images, sizes },
    ref,
  ) => {
    const [loading, setLoading] = useState(false);
    return (
      <figure ref={ref} className="ImageGallery-container">
        {loading && (
          <div className="ImageGallery-loader">
            <SvgSpinner />
          </div>
        )}
        <Img
          ref={(img) => setImageRefLoading(img, setLoading)}
          src={src}
          srcSet={srcSet}
          sizes={sizes}
          className="ImageGallery-imageDesktop"
        />
        {sectionLabel && !loading && (
          <figcaption className="ImageGallery-caption">
            {sectionLabel}
          </figcaption>
        )}
        <GalleryNavigation
          images={images}
          selectNext={selectNext}
          selectPrevious={selectPrevious}
        />
      </figure>
    );
  },
);
DesktopImage.propTypes = {
  sectionLabel: PropTypes.string,
  src: PropTypes.string,
  srcSet: PropTypes.string,
  selectNext: PropTypes.func,
  selectPrevious: PropTypes.func,
  images: PropTypes.array,
  sizes: PropTypes.string,
};

export const DesktopCoverImage = React.forwardRef(
  (
    {
      src,
      srcSet,
      images,
      selectNext,
      selectPrevious,
      sizes,
      imageLoadingStrategy,
    },
    ref,
  ) => {
    const [loading, setLoading] = useState(false);
    return (
      <div ref={ref}>
        {loading && (
          <div className="ImageGallery-loader">
            <SvgSpinner />
          </div>
        )}
        <CoverImage
          imgRef={(img) => setImageRefLoading(img, setLoading)}
          className="ImageGallery-container"
          src={src}
          srcSet={srcSet}
          sizes={sizes}
          loading={imageLoadingStrategy}
        >
          <GalleryNavigation
            images={images}
            selectNext={selectNext}
            selectPrevious={selectPrevious}
          />
        </CoverImage>
      </div>
    );
  },
);
DesktopCoverImage.propTypes = {
  src: PropTypes.string,
  srcSet: PropTypes.string,
  images: PropTypes.array,
  selectNext: PropTypes.func,
  selectPrevious: PropTypes.func,
  sizes: PropTypes.string,
  imageLoadingStrategy: PropTypes.oneOf[("eager", "lazy")],
};
