import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  t: PropTypes.func.isRequired,
  listing: PropTypes.shape({
    roomsInformation: PropTypes.array.isRequired,
    accommodates: PropTypes.number.isRequired,
    area: PropTypes.number.isRequired,
  }).isRequired,
  verbose: PropTypes.bool,
  normalSize: PropTypes.bool,
};

function ListingDetailsBrief({ t, listing }) {
  const rooms = listing.roomsInformation?.length || 1;

  return (
    <div className="ListingDetailsBrief">
      <span className="info">
        {t("spages.listing.ListingDetailsPageBase.stats.rooms", {
          rooms,
        })}
      </span>
      <span className="info">
        {t("components.ListingItem.adults", {
          adults: listing.accommodates,
        })}
      </span>
      <span className="info ListingDetailsBrief-info--area">
        {listing.area} m²
      </span>
    </div>
  );
}

ListingDetailsBrief.propTypes = propTypes;

export default ListingDetailsBrief;
