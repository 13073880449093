export const setImageRefLoading = (img, setLoading) => {
  if (!img) {
    return;
  }
  setLoading(true);

  const updateFunc = () => {
    setLoading(false);
  };
  img.onload = updateFunc;
  if (img.complete) {
    updateFunc();
    img.onload = null;
  }
};

export const getScrollValue = (container, image) => {
  if (!container || !image) {
    return 0;
  }
  const imageCenter = image.offsetLeft + image.offsetWidth / 2;
  const containerCenter = container.offsetWidth / 2;

  return imageCenter - containerCenter;
};
