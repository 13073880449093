import React from "react";

const Icon = () => (
  <svg
    width="14"
    height="11"
    className="StyledCheckbox-iconContainer-icon"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 .77l-9.575 9.476L0 5.056l.745-.873L4.5 6.643 13.293 0 14 .77z"
      fill="#FFF"
    />
  </svg>
);

export default Icon;
