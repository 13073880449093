import React from "react";
import PropTypes from "prop-types";

import "./ProgressBar.scss";

const ProgressBar = ({ calculatedProgress }) => {
  return (
    <div className="ProgressBar">
      <div className="ProgressBar-wrapper">
        <div
          className="ProgressBar-indicator"
          style={{ width: `${calculatedProgress}%` }}
        />
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  calculatedProgress: PropTypes.number,
};

export default ProgressBar;
