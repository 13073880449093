import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import dayjs from "dayjs";
import serialize from "form-serialize";
import omit from "lodash/omit";
import { Button } from "../../../../../components/DesignSystem/Button/Button";
import * as defaultListingFilter from "../../../../../utils/default-listing-filter";
import { isDefaultFilter } from "../../../../../utils/filterUtils";
import AmenitiesFilter from "./AmenitiesFilter";
import DateFilter from "./DateFilter";
import MobileDateFilter from "./MobileDateFilter";
import MoreFilters from "./MoreFilters";
import PriceFilter from "./PriceFilter/PriceFilter";
import TenantFilter from "./TenantFilter";

const FilterModal = loadable(() => import("./FilterModal/FilterModal"), {
  ssr: false,
});

const SaveFiltersManager = loadable(() => import("./SaveFiltersManager"), {
  ssr: false,
});

const getAmenitiesMap = (amenities) => {
  const amenitiesArray = amenities?.split(",") || [];
  return new Map([
    ["washingMachine", amenitiesArray.includes("washingMachine")],
    ["dryer", amenitiesArray.includes("dryer")],
    ["balconyOrTerrace", amenitiesArray.includes("balconyOrTerrace")],
    ["dishWasher", amenitiesArray.includes("dishWasher")],
    ["deskOrWorkspace", amenitiesArray.includes("deskOrWorkspace")],
    ["tv", amenitiesArray.includes("tv")],
    ["elevator", amenitiesArray.includes("elevator")],
    ["wheelchair", amenitiesArray.includes("wheelchair")],
  ]);
};

const parseHouseRules = (query) => ({
  petsAllowed: query?.petRules === "true" || false,
  smokingAllowed: query?.smokingRules === "true" || false,
});

const getToday = () => dayjs.utc().format("YYYY-MM-DD");

class ListingFilter extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    disableInteraction: PropTypes.bool,
    isSmallScreen: PropTypes.bool.isRequired,
    updateURL: PropTypes.func.isRequired,
    regionSlug: PropTypes.string,
    query: PropTypes.object,
    lang: PropTypes.string.isRequired,
    analyticsEvent: PropTypes.func.isRequired,
    url: PropTypes.func.isRequired,
    currentUrl: PropTypes.string.isRequired,
    regionBbox: PropTypes.string,
    isUserLoggedIn: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    const today = getToday();
    const from = props?.query?.from || today;

    this.state = {
      toHovered: null,
      amenities: getAmenitiesMap(props?.query?.amenities),
      selectedFilter: null,
      minDateFrom: today,
      minDateTo: this.getMinDateTo(from),
      from,
      to: props?.query?.to || null,
      minAccommodates: Number(props?.query?.minAccommodates) || 1,
      minPrice: Number(props?.query?.minPrice) || null,
      maxPrice: Number(props?.query?.maxPrice) || null,
      minRooms: Number(props?.query?.minRooms) || 1,
      houseRules: parseHouseRules(props.query),
      totalSingleBeds: Number(props?.query?.totalSingleBeds) || 0,
      totalDoubleBeds: Number(props?.query?.totalDoubleBeds) || 0,
      homeType: props?.query?.homeType || null,
      isFilterModalVisible: false,
      selectedSearchProfile: null,
    };
  }

  getMinDateTo = (from) =>
    dayjs
      .utc(from || this.state.from || getToday())
      .add(1, "month")
      .subtract(1, "day")
      .format("YYYY-MM-DD");

  clearMobileFilters = () => {
    this.setState({
      amenities: getAmenitiesMap(null),
      minAccommodates: 1,
      minRooms: 1,
      minPrice: null,
      maxPrice: null,
      houseRules: parseHouseRules(null),
      totalSingleBeds: 0,
      totalDoubleBeds: 0,
      homeType: "",
    });
  };

  hasMobileFilters = () => {
    return (
      this.state.amenities.length > 0 ||
      this.state.minAccommodates > 1 ||
      this.state.minRooms > 1 ||
      this.state.minPrice !== null ||
      this.state.maxPrice !== null ||
      this.state.totalSingleBeds > 0 ||
      this.state.totalDoubleBeds > 0
    );
  };

  onSubmit = (formElement = this.form) => {
    this.setState({ selectedSearchProfile: null });
    if (formElement.amenities) {
      formElement.amenities.value = [...this.state.amenities.entries()]
        .filter(([amenityName, amenityChecked]) => {
          return amenityChecked ? amenityName : false;
        })
        .map(([amenity]) => amenity)
        .join(",");
    }

    // Converting noDeposit value to boolean (which is expected by backend). By default, checked = true will set value = "on".
    if (formElement.noDeposit?.checked === true) {
      formElement.noDeposit.value = true;
    }

    const data = serialize(formElement, { hash: true, empty: true });
    this.setState({ selectedFilter: null });

    // We're closing the filter modal.
    // this.props.onChange function will update the URL.
    if (this.props.isSmallScreen && this.state.isFilterModalVisible) {
      this.setState({ isFilterModalVisible: false });
    }

    Object.entries(data).forEach(([filter, value]) => {
      if (
        filter !== "maxPriceTemp" &&
        filter !== "minPriceTemp" &&
        isDefaultFilter({ [filter]: value })
      ) {
        data[filter] = null;
      }
    });

    this.props.onChange(data);
  };

  getForm = () => this.form;

  onFilterSelect = (filter) => {
    // The current filter is toggled.
    if (this.state.selectedFilter === filter) {
      return this.setState({ selectedFilter: null });
    }

    this.setState({ selectedFilter: filter });
  };

  onHoverDayTo = (day) => {
    this.setState({ toHovered: day && dayjs.utc(day).format("YYYY-MM-DD") });
  };

  setDateFilter = (key, val) => {
    let value = val;

    if (key === "from") {
      if (!value) {
        value = dayjs.utc().format("YYYY-MM-DD");
      }

      if (dayjs.utc(value).isBefore(this.state.minDateFrom)) return;

      if (dayjs.utc(this.state.to).isBefore(dayjs.utc(value))) {
        this.setState({
          to: dayjs.utc(value).add(1, "month").format("YYYY-MM-DD"),
        });
      }
    }

    if (key === "to") {
      if (dayjs.utc(value).isBefore(this.getMinDateTo())) return;
    }

    const form = this.getForm();
    if (form && form[key]) form[key].value = value;

    const newState = { [key]: value };
    if (key === "from") newState.minDateTo = this.getMinDateTo(value);
    this.setState(newState);

    defaultListingFilter.set(key, value);
  };

  showFilterModal = () => {
    this.setState({ isFilterModalVisible: true });
  };

  closeFilterModal = () => {
    this.setState({ isFilterModalVisible: false });
  };

  applyDateFilters = () => {
    const { from, to } = this.state;
    this.form.from.value = from;
    this.form.to.value = to;
  };

  setMinAccommodates = (minAccommodates, callback) => {
    this.setState({ minAccommodates }, callback);
  };

  setMinRooms = (minRooms, callback) => {
    this.setState({ minRooms }, callback);
  };

  setTotalSingleBeds = (totalSingleBeds) => {
    this.setState({ totalSingleBeds });
  };

  setTotalDoubleBeds = (totalDoubleBeds) => {
    this.setState({ totalDoubleBeds });
  };

  setHouseRules = (houseRules) => {
    this.setState({ houseRules });
  };

  setAccommodationType = (homeType) => {
    this.setState({ homeType });
  };

  setMinPrice = (minPrice) => {
    this.setState({ minPrice });
  };

  setMaxPrice = (maxPrice) => {
    this.setState({ maxPrice });
  };

  amenitiesToggle = (amenity, checked) => {
    const newState = new Map(this.state.amenities);
    newState.set(amenity, checked);
    this.setState({ amenities: newState });
  };

  mobileOnChangeFrom = (isoDateStr) => {
    // User reset the date filters.
    if (!isoDateStr) {
      this.setDateFilter("from", "");
      this.setDateFilter("to", "");

      return;
    }

    this.setDateFilter("from", isoDateStr);
    this.onSubmit();
  };

  mobileOnChangeTo = (isoDateStr) => {
    this.setDateFilter("to", isoDateStr);
    this.onSubmit();
  };

  render() {
    const { t, isSmallScreen, regionSlug, query, isUserLoggedIn } = this.props;
    const { minPrice, maxPrice, bbox, noDeposit } = query;
    const isNoDepositFilterOn = !!noDeposit;
    const regionOrBbox = bbox || regionSlug;
    const hasDateFilter = !isDefaultFilter({ from: query.from });
    const hasOtherFilter = !isDefaultFilter(omit(query, ["from", "to"]));

    const saveFiltersManagerProps = {
      t,
      url: this.props.url,
      query,
      regionBbox: this.props.regionBbox,
      currentUrl: this.props.currentUrl,
      updateURL: this.props.updateURL,
      onChange: this.props.onChange,
      selectedProfile: this.state.selectedSearchProfile,
      setSelectedProfile: (selectedSearchProfile) =>
        this.setState({ selectedSearchProfile }),
    };

    // Show filter as a modal on small screens.
    if (isSmallScreen && this.state.isFilterModalVisible) {
      return (
        <FilterModal
          onSubmit={this.onSubmit}
          onClose={this.closeFilterModal}
          amenitiesMap={this.state.amenities}
          onModalOpen={() =>
            this.setState({
              amenities: getAmenitiesMap(query?.amenities),
            })
          }
          amenitiesToggle={this.amenitiesToggle}
          minAccommodates={this.state.minAccommodates}
          setMinAccommodates={this.setMinAccommodates}
          minRooms={this.state.minRooms}
          setMinRooms={this.setMinRooms}
          totalSingleBeds={this.state.totalSingleBeds}
          totalDoubleBeds={this.state.totalDoubleBeds}
          setTotalSingleBeds={this.setTotalSingleBeds}
          setTotalDoubleBeds={this.setTotalDoubleBeds}
          clearFilters={this.clearMobileFilters}
          minPrice={minPrice ? parseInt(minPrice, 10) : null}
          maxPrice={maxPrice ? parseInt(maxPrice, 10) : null}
          isNoDepositFilterOn={isNoDepositFilterOn}
          t={t}
          regionSlug={regionOrBbox}
          query={query}
          houseRules={this.state.houseRules}
          setHouseRules={this.setHouseRules}
          homeType={this.state.homeType}
          setAccommodationType={this.setAccommodationType}
        />
      );
    }

    return (
      <form
        className="ListingFilter"
        onSubmit={(e) => {
          e.preventDefault();
          this.onSubmit();
        }}
        ref={(elm) => (this.form = elm)}
      >
        {/* Show filter modal toggle on mobile */}
        {isSmallScreen && !this.state.isFilterModalVisible && (
          <React.Fragment>
            <MobileDateFilter
              t={t}
              from={this.state.from}
              to={this.state.to}
              minDateFrom={this.state.minDateFrom}
              minDateTo={this.state.minDateTo}
              setDateFilter={this.setDateFilter}
              hasFilter={hasDateFilter}
              onChangeFrom={this.mobileOnChangeFrom}
              onChangeTo={this.mobileOnChangeTo}
              popOverClassName="onListingsPage"
            />
            <Button
              type="button"
              colorVariant={!hasOtherFilter ? "white" : "brandPurple"}
              className="ListingFilterButton"
              onClick={this.showFilterModal}
            >
              {t("filters")}
            </Button>
            {/* TODO Remove isUserLoggedIn check when the DEV-13951 is resolved */}
            {isUserLoggedIn && (
              <SaveFiltersManager {...saveFiltersManagerProps} />
            )}
          </React.Fragment>
        )}

        {/* Desktop filter togglers */}
        {!isSmallScreen && (
          <div className="ListingFilter-desktopContainer">
            <DateFilter
              t={t}
              to={this.state.to}
              from={this.state.from}
              queryParams={query}
              opened={this.state.selectedFilter === "date"}
              setDateFilter={this.setDateFilter}
              onFilterSelect={this.onFilterSelect}
              onHoverDayTo={this.onHoverDayTo}
              minDateTo={this.state.minDateTo}
              toHovered={this.state.toHovered}
              hasFilters={!isDefaultFilter({ from: query.from })}
              onSubmit={() => {
                this.applyDateFilters();
                this.onSubmit();
              }}
            />
            <PriceFilter
              t={t}
              formRef={this.form}
              opened={this.state.selectedFilter === "price"}
              onFilterSelect={this.onFilterSelect}
              minPrice={minPrice ? parseInt(minPrice, 10) : null}
              maxPrice={maxPrice ? parseInt(maxPrice, 10) : null}
              isNoDepositFilterOn={isNoDepositFilterOn}
              regionSlug={regionSlug}
              hasFilters={
                !isDefaultFilter({
                  minPrice: query.minPrice,
                  maxPrice: query.maxPrice,
                  noDeposit: query.noDeposit,
                })
              }
              query={query}
              onSubmit={this.onSubmit}
            />
            <TenantFilter
              t={t}
              minAccommodates={Number(query.minAccommodates) || 1}
              onFilterSelect={this.onFilterSelect}
              opened={this.state.selectedFilter === "tenant"}
              setMinAccommodates={this.setMinAccommodates}
              hasFilters={
                !isDefaultFilter({ minAccommodates: query.minAccommodates })
              }
              onSubmit={() => {
                this.form.minAccommodates.value = this.state.minAccommodates;
                this.onSubmit(this.form);
              }}
            />
            <AmenitiesFilter
              t={t}
              defaultAmenities={query?.amenities?.split(",") || []}
              onFilterSelect={this.onFilterSelect}
              opened={this.state.selectedFilter === "amenities"}
              amenitiesMap={this.state.amenities}
              hasFilters={!isDefaultFilter({ amenities: query.amenities })}
              amenitiesToggle={this.amenitiesToggle}
              onClear={(evt) => {
                if (!evt) {
                  this.setState({
                    amenities: getAmenitiesMap(query?.amenities),
                  });
                } else {
                  this.setState({ amenities: getAmenitiesMap(null) });
                }
              }}
              onSubmit={() => {
                this.onSubmit(this.form);
              }}
            />
            <MoreFilters
              t={t}
              onFilterSelect={this.onFilterSelect}
              isOpen={this.state.selectedFilter === "moreFilters"}
              query={query}
              onSubmit={() => this.onSubmit(this.form)}
              analytics={this.props.analyticsEvent}
              minRooms={this.state.minRooms}
              setMinRooms={this.setMinRooms}
              houseRules={this.state.houseRules}
              setHouseRules={this.setHouseRules}
              homeType={this.state.homeType}
              setAccommodationType={this.setAccommodationType}
              totalSingleBeds={this.state.totalSingleBeds}
              totalDoubleBeds={this.state.totalDoubleBeds}
              setTotalSingleBeds={this.setTotalSingleBeds}
              setTotalDoubleBeds={this.setTotalDoubleBeds}
              hasFilters={
                !isDefaultFilter({
                  minRooms: query.minRooms,
                  minSize: query.minSize,
                  petRules: query.petRules,
                  smokingRules: query.smokingRules,
                  totalSingleBeds: query.totalSingleBeds,
                  totalDoubleBeds: query.totalDoubleBeds,
                  homeType: query.homeType,
                })
              }
            />
            {/* TODO Remove isUserLoggedIn check when the DEV-13951 is resolved */}
            {isUserLoggedIn && (
              <SaveFiltersManager {...saveFiltersManagerProps} />
            )}
          </div>
        )}
      </form>
    );
  }
}

export default ListingFilter;
